import React from "react";

const EntryPage = ({children}) => {
  return (
    <div className="wrapper" >
        <main
          className='entry-page__primary-container'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          {children}
        </main>
    </div>
  )
}

export default EntryPage;
