import React, { useState } from 'react';

const QuestionnaireRules = ({filling_rules, update}) => {
  let [rules, changeRules] = useState(filling_rules !== undefined ? filling_rules : "");

    return (
        <div className="w-[100%] p-[20px] flex flex-col ">
            <div className="text-xl font-bold text-center">Информация для заполнения</div>
            <div className="h-[20px]"></div>
            <textarea
                className="h-[200px] pl-[20px] w-[100%] bg-gray-200 rounded-md"
                value={rules}
                onChange={(val) => {
                    changeRules(val.currentTarget.value);
                    update(val.currentTarget.value);
                }}
            />
        </div>
    )
};

export default QuestionnaireRules;
