import React from 'react';
import { frontTypes, types } from '../../../../variables';
import deleteIcon from "../../../../delete.svg";
import AddOptionButton from '../options/AddOptionButton';
import NestedChoiceOptions from '../options/NestedChoiceOptions';
import SubQuestionBar from './SubQuestionBar';
import SubQuestion from './SubQuestion';

// Рисует вс подвопрос. Если подвопрос с типом choice - то дополнительно рисует список его опций и кнопку "добавить опцию".
const SubQuestions = ({
  subQuestions,
  country,
  sectionIndex,
  questionIndex,
  optionIndex,
  updateCountry,
  updateDropOption,
}) => {
  const changeSubQuestion = (event, subQuestionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions[questionIndex].options[optionIndex].questions[subQuestionIndex].question = event.target.value;
    updateCountry(clone);
  }
  const deleteSubQuestion = (subQuestionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions[questionIndex].options[optionIndex].questions.splice(subQuestionIndex, 1);
    updateCountry(clone);
  }
  // итерация через подвопросы опции дропдауна
  return subQuestions.map((subQuestion, subQuestionIndex) => {
    return (
      <SubQuestion
        country={country}
        subQuestion={subQuestion}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
        optionIndex={optionIndex}
        subQuestionIndex={subQuestionIndex}
        updateCountry={updateCountry}
        updateDropOption={updateDropOption}
        deleteSubQuestion={deleteSubQuestion}
        changeSubQuestion={changeSubQuestion}
      />
    )
  })
};

export default SubQuestions;