import React from 'react';
import deleteIcon from "../../../delete.svg";

const QuestionListItem = ({question, index, country, updateCountry}) => {
  return (
    <div className="flex flex-col mb-[20px] ">
      <div className="text-sm">{(index + 1) + ". Вопрос"}</div>
      <div className="flex flex-row items-center justify-between">
        <input
          onChange={(val) => {
            let clone = structuredClone(country);
            clone.faq[index].question = val.currentTarget.value;
            updateCountry(clone)
          }}
          value={question.question}
          placeholder="Введите часто задаваемый вопрос"
          className="pl-[20px] h-[40px] bg-gray-200 w-[60%] rounded-md "
        ></input>
        <div
          onClick={() => {
            let clone = structuredClone(country);
            clone.faq = [...clone.faq.slice(0, index), ...clone.faq.slice(index + 1)];
            updateCountry(clone);
          }}
          className="h-[30px] cursor-pointer w-[30px] rounded-[30px] bg-gray-200 flex items-center justify-center"
        >
          <img className='h-[20px] w-[20px]' src={deleteIcon}></img>
        </div>
      </div>
      <div className="h-[5px]"></div>
      <div className="text-sm">Ответ</div>
      <textarea
        onChange={(val) => {
          let clone = structuredClone(country);
          clone.faq[index].answer = val.currentTarget.value;
          updateCountry(clone);
        }}
        value={question.answer}
        placeholder="Введите ответ"
        className="pl-[20px] h-[100px] bg-gray-200 w-[100%] rounded-md"
      />
    </div>
  )
};

export default QuestionListItem;