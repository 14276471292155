import React from 'react';

export function visaTypeRenderer(visaTypes, changeVisaType, visaType) {
    const typesFront = ['Нужна виза', 'Виза по прилету', 'Без визы', 'Запрещен вьезд'];
    let renderedTypes = [];
    for (let i = 0; i < visaTypes.length; i++) {
      renderedTypes.push(
      <div className="flex flex-row">
        <div 
          // onClick={() => {      // временно заблокировано
          //   changeVisaType(i);
          // }} 
          // className={`h-[50px] w-[200px] ${visaType == i ? "border-blue-600 border-[3px]" : "border-black/[0.1] border-[2px]"} rounded-md bg-white flex flex-row items-center justify-center`}  // временно заблокировано
          className={`h-[50px] w-[200px] border-black/[0.1] border-[2px] rounded-md bg-white flex flex-row items-center justify-center`}
        >
          {typesFront[i]}
        </div>
        <div className="w-[20px]"></div>
      </div>);
    }
    return renderedTypes;
  }
