import React from 'react';
import ChosenGiftPreview from './ChosenGiftPreview';

const SelectedGiftsPreviews = ({giftsData, chosenGiftsState}) => {
  const chosenGiftsPreview = chosenGiftsState.reduce((acc, id) => {
    const giftData = giftsData.find(giftData => giftData.id === id);
    if (giftData) {
      acc.push(<ChosenGiftPreview key={id} gift={giftData}/>)
      return acc;
    }
    return acc;
  }, [])

  return (
    <div
      className='flex flex-nowrap	w-[100%] h-[150px] gap-[2%] overflow-auto	p-[10px]'
    >
      {chosenGiftsPreview}
    </div>
  );
};

export default SelectedGiftsPreviews;