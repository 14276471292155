import React from 'react';

export function questionTypes(types, updateQuestionObj, frontTypes, newQuestionObj) {
    let questions = [];
    for (let i = 0; i < types.length; i++) {
      questions.push(
        <div
          onClick={() => { updateQuestionObj((prevState) => ({ ...prevState, 'type': types[i] })) }}
          className={`h-[50px] w-[300px] bg-white rounded-md ${newQuestionObj['type'] !== types[i] ? "border-black/[0.1] border-[2px]" : "border-blue-700 border-[5px]"} flex justify-center items-center`}
        >
          {frontTypes[i]}
        </div>
      );
    }
    return questions;
}