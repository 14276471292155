import React from 'react';

const ChosenGiftPreview = ({gift}) => {
  console.log(gift)
  return (
    <div className='inline-block shrink-0 w-[100px] h-[100%]'>
      <img className='block w-[100%] h-[100%] object-contain ' src={gift.pictureLinks[0]} alt={gift.name}/>
    </div>
  );
};

export default ChosenGiftPreview;