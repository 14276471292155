import React from 'react';
import Option from './Option';

const Options = ({
  question,
  country,
  updateCountry,
  optionQuestion,
  updateDropOption,
  updateOptionQuestion,
  sectionIndex,
  questionIndex
}) => {
  const deleteOption = (optionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions[questionIndex].options.splice(optionIndex, 1);
    updateCountry(clone);
  }

  const changeOptionsName = (event, optionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions[questionIndex].options[optionIndex].option = event.target.value;
    updateCountry(clone);
  }

  return question.options.map((option, optionIndex) => {
    return (
      <>
        <Option
          question={question}
          option={option}
          country={country}
          updateCountry={updateCountry}
          optionQuestion={optionQuestion}
          updateDropOption={updateDropOption}
          updateOptionQuestion={updateOptionQuestion}
          sectionIndex={sectionIndex}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          deleteOption={deleteOption}
          changeOptionsName={changeOptionsName}
        />
        <div className="h-[10px]"></div>
      </>
    )
  })
};

export default Options;