import React from 'react';
import deleteIcon from "../../../../delete.svg";
import EditableText from '../EditableText';

const OptionBar = ({
  option,
  optionIndex,
  optionNum,
  deleteOption,
  changeOptionsName,
}) => {

  const handleDeleteOption = () => {
    deleteOption(optionIndex);
  }

  return (
    <div className="flex flex-row items-center">
      <p className='w-[60px] text-yellow-500	 font-bold'>опция</p>
      <div
        className="h-[50px] w-[500px] bg-white border-[2px] border-black/[0.1] rounded-md flex items-center justify-center gap-[5px]"
      >
        <span>{optionNum}</span>
        <EditableText
          value={option.option}
          index={optionIndex}
          changeFunc={changeOptionsName}
        />
      </div>
      <div className="w-[20px]"></div>
      <div
        onClick={handleDeleteOption}
        className="h-[40px] w-[40px] bg-gray-300 rounded-[40px] flex items-center justify-center"
      >
        <img className="h-[20px]" src={deleteIcon}></img>
      </div>
    </div>
  );
};

export default OptionBar;
