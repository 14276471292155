import { Button, Modal } from 'antd';
import { collection, query } from 'firebase/firestore';
import React, { useState } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase/firebase.services';
import loadingIcon from "../../../loading.gif";
import SelectedGiftsPreviews from './SelectedGiftsPreviews';
import AllGiftsCards from './AllGiftsCards';
import ApplyOrCancel from '../../../components/ApplyOrCancel';

const ChooseGift = ({chosenGifts, country, updateCountry}) => {
  const [ giftsData, giftsLoading, giftsError, giftsCollSnap, reload ] = useCollectionDataOnce(query(collection(db, 'gifts')));
  const [ popupOpened, setPopupOpened ] = useState(false)
  const [ chosenGiftsState, setChosenGiftsState ] = useState(chosenGifts);
  // const [ wasChanged, setWasChanged ] = useState(false)

  if(giftsError) {
    console.log(giftsError);
    return <div style={{font:'700 48px sans-serif'}}>Ошибка!</div>
  }

  const popupOpenHandler = () => {
    setPopupOpened(true);
  }

  const popupCloseHandler = () => {
    setPopupOpened(false);
    setChosenGiftsState(chosenGifts);
  }

  const selectGift = (id) => {
    const alreadyIncluded = chosenGiftsState.includes(id);
    // setWasChanged(true)
    if(alreadyIncluded) {
      setChosenGiftsState(prev => prev.filter(giftId => giftId !== id));
    } else {
      setChosenGiftsState(prev => [...prev, id]);
    }
  }

  const saveChanges = async() => {
    const countryClone = structuredClone(country);
    countryClone.chosenGifts = chosenGiftsState;
    updateCountry(countryClone)
    setPopupOpened(false)
    // setWasChanged(false)
    // reload();
  }

  return (
    <div className="p-[20px] w-[58%] border-black/[0.3] border-[2px] rounded-md">
      <div className='flex justify-between mb-[3%]'>
        <p className="font-bold text-2xl">Выбор подарка</p>
        <Button onClick={popupOpenHandler}>Выбрать </Button>
      </div>
      {giftsLoading ? (
        <img className='w-[50px] h-[50px]' src={loadingIcon} alt='loading'></img>
      ):(
        <>
          <SelectedGiftsPreviews giftsData={giftsData} chosenGiftsState={chosenGiftsState}/>
          <Modal
            open={popupOpened}
            width='70%'
            // okText='Применить'
            // okButtonProps={{
            //   disabled: !wasChanged
            // }}
            onCancel={popupCloseHandler}
            footer={(
              <div className='flex w-[100%] justify-end'>
                <ApplyOrCancel onCancel={popupCloseHandler} onApply={saveChanges}/>
              </div>
            )}
          >
            <AllGiftsCards giftsData={giftsData} selectGift={selectGift} chosenGiftsState={chosenGiftsState}/>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ChooseGift;