import React from 'react';
import QuestionListItem from './QuestionListItem';

const QuestionList = ({country, updateCountry}) => {
  if(!country.faq) {
    return null; // не во всех странах есть массив faq по умолчанию
  }

  return country.faq.map((question, index) => {
    return <QuestionListItem key={index} question={question} index={index} country={country} updateCountry={updateCountry}/>
  })
};

export default QuestionList;
