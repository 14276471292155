import React, { useState } from 'react';
import MyEditor from '../../../CKeditor/components/MyEditor';

const visaInfoSturcture = {
  generalInfo: 'generalInfo',
  requiredDocs: 'requiredDocs',
  submissionSteps: 'submissionSteps',
}

const VisaInfo = ({visaInfo, updateCountry}) => {

  const visaInfoChangeHandler = (editor, fieldName) => {
    updateCountry(prev => {
      console.log(editor.getData())
      const countryClone =  structuredClone(prev);
      countryClone.visaInfo[fieldName] = editor.getData();
      return countryClone;
    })
  }
  // const visaInfoChangeHandler = (e, fieldName) => {
  //   updateCountry(prev => {
  //     const countryClone =  structuredClone(prev);
  //     countryClone.visaInfo[fieldName] = e.target.value;
  //     return countryClone;
  //   })
  // }

  return (
    <div className="w-[100%] p-[20px] flex flex-col border-black/[0.3] border-[2px] rounded-md">
      <div className="text-xl font-bold text-center">2. Информация о визе</div>
      <div className="h-[20px]"></div>
        <div className='mb-[15px]'>
          <h3 className='text-lg font-bold mb-0.5'>
            Общая информация:
          </h3>
          <MyEditor
            initialData={visaInfo[visaInfoSturcture.generalInfo]}
            changeText={(editor) => visaInfoChangeHandler(editor, visaInfoSturcture.generalInfo)}
          />
          {/* <textarea
            className="h-[200px] pl-[20px] w-[100%] bg-gray-200 rounded-md"
            value={visaInfo[visaInfoSturcture.generalInfo]}
            onChange={(e) => visaInfoChangeHandler(e, visaInfoSturcture.generalInfo)}
          /> */}
        </div>


        <div className='mb-[15px]'>
          <h3 className='text-lg font-bold mb-0.5'>
            Требуемые документы:
          </h3>
          <MyEditor
            initialData={visaInfo[visaInfoSturcture.requiredDocs]}
            changeText={(editor) => visaInfoChangeHandler(editor, visaInfoSturcture.requiredDocs)}
          />
          {/* <textarea
            className="h-[200px] pl-[20px] w-[100%] bg-gray-200 rounded-md"
            value={visaInfo[visaInfoSturcture.requiredDocs]}
            onChange={(e) => visaInfoChangeHandler(e, visaInfoSturcture.requiredDocs)}
          /> */}
        </div>


        <div className='mb-[15px]'>
          <h3 className='text-lg font-bold mb-0.5'>
            Алгоритм подачи:
          </h3>
          <MyEditor
            initialData={visaInfo[visaInfoSturcture.submissionSteps]}
            changeText={(editor) => visaInfoChangeHandler(editor, visaInfoSturcture.submissionSteps)}
          />
          {/* <textarea
            className="h-[200px] pl-[20px] w-[100%] bg-gray-200 rounded-md"
            value={visaInfo[visaInfoSturcture.submissionSteps]}
            onChange={(e) => visaInfoChangeHandler(e, visaInfoSturcture.submissionSteps)}
          /> */}
        </div>

    </div>
  )
}

export default VisaInfo;