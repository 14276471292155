import React from 'react';
import SubQuestions from '../subquestions/SubQuestions';
import AddSubQuestionButton from '../subquestions/AddSubQuestionButton';
import OptionBar from './OptionBar';

const Option = ({question, option, country, updateCountry, optionQuestion, updateDropOption, updateOptionQuestion, sectionIndex, questionIndex, optionIndex, deleteOption, changeOptionsName}) => {
  const addSubQuestion = () => {
    let clone = structuredClone(optionQuestion);
    clone.sectionIndex = sectionIndex;
    clone.questionIndex = questionIndex;
    clone.optionIndex = optionIndex;
    clone.show = true;
    updateOptionQuestion(clone);
  }

  const optionNum = `Опция ${optionIndex + 1}: `;

  return (
    <div>
      <OptionBar
        option={option}
        optionIndex={optionIndex}
        optionNum={optionNum}
        deleteOption={deleteOption}
        changeOptionsName={changeOptionsName}
      />
      {question.type === "dropdown" ? ( // разрешаем рисовать кнопки "Добавить вопрос к опции +"  если тип вопроса dropdown
        <div>
          <div className="pl-[30px]">
            <div className="h-[10px]"></div>
            <SubQuestions
              subQuestions={option.questions}
              country={country}
              sectionIndex={sectionIndex}
              questionIndex={questionIndex}
              optionIndex={optionIndex}
              updateCountry={updateCountry}
              updateDropOption={updateDropOption}
            />
            <AddSubQuestionButton
              addSubQuestion={addSubQuestion}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Option;