import React, { useEffect, useRef, useState } from 'react';

const EditableText = ({value, index, changeFunc}) => {
  const [ isEdit, setIsEdit ] = useState(false);
  const inputRef = useRef(null)

  useEffect(() => {
    if(isEdit) {
      inputRef.current.focus();
    }
  })

  const handleChangeSectionName = (event) => {
    changeFunc(event, index)
  }

  const handleEdit = () => {
    setIsEdit(true);

  }
  const handleInputBlur = () => {
    setIsEdit(false);
  }

  return isEdit ? (
    <input
      className='px-[3px] h-[40px] w-[70%]'
      ref={inputRef}
      value={value}
      onChange={handleChangeSectionName}
      onBlur={handleInputBlur}
    />
  ):(
    <p
      className='max-w-[90%]'
      onClick={handleEdit}
    >
      {value}
    </p>
  )
};

export default EditableText;