import React from 'react';
import { visaTypes } from '../../../variables';
import CountryBar from './CountryBar';
import sortRuCountries from '../../../utils/sortRuCountries';

const RenderedCountries = ({
  ruCountries,
  fromTopAdd,
  countries,
  country,
  addCountry,
}) => {

  const renderedCountries = sortRuCountries(ruCountries).reduce((acc, ruCountry) => { // цикл по странам на русском языке.
    let exists = false;
    if (!fromTopAdd) {
      visaTypes.forEach((visaType) => {
        let pickIndex = country.relations[visaType].findIndex(elem => elem === ruCountry);
        if (pickIndex !== -1) exists = true;
      })
    } else {
      countries.forEach(country => {
        if(country.props.value === ruCountry) exists = true; // если страна из 'c' есть в опциях 'countries' - помечаем, что такая страна есть.
      })
    }
    if(!exists) {
      acc.push(<CountryBar key={ruCountry} ruCountry={ruCountry} addCountry={addCountry}/>)
      return acc;
    }
    return acc;
  }, [])

  return renderedCountries;
};

export default RenderedCountries;
