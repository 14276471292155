import React from 'react';

const AddOptionButton = ({
  addOption 
}) => {
  return (
    <>
      <div
        onClick={addOption}
        className="h-[50px] w-[500px] rounded-md bg-gray-200 flex items-center justify-center ml-[60px]"
      >
        Добавить опцию +
      </div>
      <div className="h-[30px]"></div>
    </>
  );
};

export default AddOptionButton;
