import {React, Component} from 'react';

export function Durations(props){
    return <div>
        <div className="">2.3 Скорость рассмотрения заявки (посольством)</div>
        <div className="h-[20px]"></div>
        <div className="flex flex-row items-center">
        <input
          onChange={(val)=>{
            let clone = structuredClone(props.country);
            // clone['review_duration'] = val.currentTarget.value.length != 0 ? parseFloat(val.currentTarget.value) : 0;
            clone['review_duration'] = val.currentTarget.value;
            props.update(clone);
          }}
          
          value={props.country['review_duration'].toString()}
          className="h-[40px] w-[300px] rounded-md pl-[20px]"
        />
        <div className="w-[20px]"></div>
        <div className="text-md">Дней</div>
        </div>
        <div className="h-[20px]"></div>
        <div className="">2.4 Срок оформления после принятия заявки (посольством)</div>
        <div className="h-[20px]"></div>
        <div className="flex flex-row items-center">
        <input 
          disabled
          onChange={(val)=>{
              let clone = structuredClone(props.country);
              clone['visa_process_duration'] = val.currentTarget.value.length != 0 ? parseFloat(val.currentTarget.value) : 0
              props.update(clone);
          }} 
          value={props.country['visa_process_duration'].toString()} 
          className="border h-[40px] w-[300px] rounded-md pl-[20px]"
        />
        <div className="w-[20px]"></div>
        <div className="text-md">Дней</div>
        </div>
    </div>;
}