import React from 'react';
import loadingIcon from "../../../loading.gif";
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { collection, query } from 'firebase/firestore';
import { db } from '../../../firebase/firebase.services';
import GiftConfigPanel from './GiftConfigPanel';

const GiftList = () => {
  const [ gifts, giftsLoading, giftsError, giftsCollSnap, reload ] = useCollectionDataOnce(query(collection(db, 'gifts')));

  if(giftsLoading) {
    return <img src={loadingIcon}></img>
  }

  if(giftsError) {
    console.log(giftsError);
    return <div style={{font:'700 48px sans-serif'}}>Ошибка!</div>
  }

  const giftsList = gifts.map(gift => {
    return <GiftConfigPanel key={gift.id} gift={gift} reloadData={reload}/>
  })
  return (
    <div className='w-[70%]'>
      {giftsList}
    </div>
  );
};

export default GiftList;