import React from 'react';
import { nanoid } from 'nanoid';
import deleteIcon from "../delete.svg"
import { MagicWidget } from './magicWidget';

export function renderAdditionalServices(country, updateCountry, updateMagic, showWhereMagic) {
    if (country["additional_services"] !== undefined) {
        let services = [];
        for (let i = 0; i < country["additional_services"].length; i++) {
            let obj = country["additional_services"][i];
            services.push(
                <div className="flex flex-col items-start ">
                    <div className="text-black/[0.5] text-xs">{`Название ${i + 1}`}</div>
                    <div className="flex flex-row items-center w-[100%]">
                        <input
                            placeholder='Название услуги'
                            onChange={(val) => {
                                let c = structuredClone(country);
                                let t = val.currentTarget.value;
                                c['additional_services'][i]['name'] = t;
                                updateCountry(c);
                            }}
                            className="h-[40px] w-[100%] bg-gray-200 rounded-md pl-[10px]" value={obj["name"]
                            } />
                        <div className="w-[20px]"></div>
                        <div onClick={() => {
                            let c = structuredClone(country);
                            c["additional_services"].splice(i, 1);
                            updateCountry(c);
                        }} className="h-[30px] w-[30px] rounded-[30px] cursor-pointer bg-gray-300 flex items-center justify-center "><img className="h-[20px]" src={deleteIcon} /></div>
                    </div>
                    <div className="h-[10px]"></div>
                    <div className="text-black/[0.5] text-xs">Описание</div>
                    <textarea
                        placeholder='Описание услуги'
                        onChange={(val) => {
                            let c = structuredClone(country);
                            let t = val.currentTarget.value;
                            c['additional_services'][i]['description'] = t;
                            updateCountry(c);
                        }}
                        className="h-[100px] w-[100%] line-clamp-2 bg-gray-200 rounded-md pl-[10px]" value={obj["description"]}
                    />
                    <div className="h-[10px]"></div>
                    <div placeholder="Цена, в тенге" className="text-black/[0.5] text-xs">Стоимость (KZT)</div>
                    <input
                        onChange={(val) => {
                            let c = structuredClone(country);
                            // in (Number.isFinite())  
                            if (!Number.isFinite(+val.target.value) || val.target.value.split('').includes('.')) return; // запрещаем печатать не цифры и точку, т.к. дробных чисел быть не должно
                            let p = val.currentTarget.value.trim();// нельзя содержать пробелы в середине цены

                            if(p.split('')[0] === '0') { // убираем 0, когда начинаем писать цену.
                              c["additional_services"][i]["price_usd"] = p.split('').slice(1).join('');
                              updateCountry(c);
                              return;
                            }
                            if (p !== '') { // нельзя сохранять пустую строку в БД.
                                c["additional_services"][i]["price_usd"] = p;
                            } else {
                                c["additional_services"][i]["price_usd"] = 0; // зписываем 0 вместо пустой строки.
                            }
                            updateCountry(c);
                        }}
                        value={obj["price_usd"]}
                        className="pl-[5px] h-[40px] w-[200px] bg-gray-200 rounded-md"
                    />
                    <div className="h-[10px]"></div>
                    {
                        obj["magic"] == null ? <div onClick={() => {
                            showWhereMagic(i);
                            updateMagic(true);
                        }} className="h-[40px] w-[100px] text-md flex items-center cursor-pointer justify-center text-blue-500 rounded-md border-blue-500 border-[2px]">Виджет +</div> :
                            <MagicWidget obj={obj["magic"]} update={() => {
                                showWhereMagic(i);
                                updateMagic(true);
                            }} />}
                    <div className="h-[20px]"></div>
                </div>
            );
        }
        services.push(
            <div
                onClick={() => {
                    let c = structuredClone(country);
                    c["additional_services"].push({
                        "id": nanoid(),
                        "name": "",
                        "description": "",
                        "price_usd": "",
                    });
                    updateCountry(c);
                }}
                className="h-[40px] cursor-pointer w-[200px] rounded-md bg-gray-300 flex items-center justify-center mx-auto"
            >
                Добавить доп. услугу +
            </div>
        );
        return services;
    } else {
        console.log('undefined from services');
        return <></>;
    }
}