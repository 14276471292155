import React, { useState, useRef } from 'react';
import { getFirestore, doc, getDoc, deleteDoc, addDoc, setDoc, Timestamp, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, db  } from '../firebase/firebase.services';
import { useNavigate } from 'react-router-dom';


function Blogs(props) {
  const navigate = useNavigate();

    async function sendBlog() {
        let title = document.getElementById("blog-header").value;
        let mainText = document.getElementById("mainText").value;
        let tfile = document.getElementById("fileInput").value;
        if (title != "" && mainText != "" && tfile != "") {
            let name = `blogs/blog${Timestamp.now().nanoseconds}`;
            const storageRef = ref(storage, name);
            let upload = {
                "text": title,
                "description": mainText,
                "image": name,
                "postTime": Timestamp.now(),
                "readTime": "2 минуты", // алгоритм придумать для прочтения
                "type": "Визы", // выбираться в админке
                "views": "2 Просмотра", // обновляться в приложении
                "author": "Нурлыгуль", // текстовое поле
            };
            addDoc(collection(db, "blogs"), upload);
            document.getElementById("blog-header").value = '';
            document.getElementById("mainText").value = '';
            document.getElementById("fileInput").value = '';
            await uploadBytes(storageRef, file);
            changeReady(false);
            prompt("Успешно добавлен блог, перезайдите.");
        }
    }
    const inputRef = useRef()

    const [file, setFiles] = useState(null);
    let [ready, changeReady] = useState(false);
    return <body className="h-[100%] w-[100%] p-[20px] items-center flex flex-col fixed bg-gray-200 overflow-scroll">
        <div className="flex flex-row w-[100%] justify-between mb-[20px]">
            <div onClick={() => navigate('/')} className="h-[40px] w-[100px] rounded-md border-black/[0.3] border-[2px] flex items-center justify-center">Назад</div>
            <div className="text-3xl font-bold"> Блоги</div>
            <div className="h-[1px] w-[100px]"></div>
        </div>
        <div className="h-[20px]"></div>
        <div className="w-[600px] p-[20px] rounded-md border-black/[0.3] border-[2px] items-start">
            <input id='blog-header' placeholder="Заголовок" className="h-[60px] w-[100%] p-[20px] bg-white rounded-md "></input>
            <div className="h-[20px]"></div>
            <input id='mainText' placeholder="Текст" className="p-[20px] break-words h-[100px] w-[100%] bg-white rounded-md"></input>
            <div className="h-[20px]"></div>
            <input ref={inputRef} id="fileInput" onChange={(e) => { setFiles(inputRef.current.files[0]) }} type="file"></input>
            <div className="h-[20px]"></div>
            <div onClick={() => { sendBlog(); }} className="h-[40px] self-end flex items-center justify-center text-white w-[100px] rounded-md bg-blue-500">Загрузить</div>
        </div>
        <div className="h-[20px]"></div>
        <OldBlogs ready={ready} changeReady={(val) => { changeReady(val) }} />

    </body>;
}

function OldBlogs(props) {
    let [render, changeRender] = useState([]);

    async function deleteBlog(id, ind) {
        await deleteDoc(doc(db, "blogs", id));
        prompt("Успешно удален пост, перезайдите.");
    }
    async function getBlogs() {
        let r = [];
        let docs = await getDocs(collection(db, "blogs"));
        for (let i = 0; i < docs.docs.length; i++) {
            let data = docs.docs[i].data();
            console.log(data);
            r.push(<div className="h-[280px] mt-[20px] w-[600px] rounded-md overflow-hidden flex flex-row border-black/[0.3] border-[2px]">
                <div className="w-[50%] h-[100%] bg-black/[0.3]">
                    <FirebaseImage cl="h-[100%] w-[100%] object-cover" firebaseRef={data["image"]} />
                </div>
                <div className="w-[50%] h-[100%] flex flex-col">
                    <div className="flex flex-col h-[220px]  p-[10px] overflow-hidden">
                        <div className="text text-xl font-bold">{data['text']}</div>
                        <div className="h-[20px]"></div>
                        <div>{data["description"]}</div>
                    </div>
                    <div className="h-[10px]"></div>
                    <div onClick={() => {
                        deleteBlog(docs.docs[i].id, i);
                    }} className="h-[40px] w-[100px] text-white self-end mr-[10px] flex items-center justify-center rounded-md bg-red-500">Удалить</div>
                    <div className="h-[10px]"></div>
                </div>
            </div>);
        }
        if (!props.ready) {
            changeRender(r);
            props.changeReady(true);
        }
    }
    getBlogs();

    return <div className="flex flex-col items-center ">{render}</div>;
}

let refs = [];

async function getImg(img, curRef, changeImg) {
        let ind = refs.findIndex((e) => e["ref"] == curRef);
        if (ind == -1) {
            let url = await getDownloadURL(ref(storage, curRef));
            changeImg(url);
            refs.push({
                "ref": curRef,
                "img": img,
            });
        } else {
            changeImg(refs[ind]["img"]);
        }
}

function FirebaseImage(props) {
    let [ready, changeReady] = useState(false);
    let [img, changeImg] = useState("");
    if (!ready) {
        getImg(img, props.firebaseRef, (val) => { changeImg(val); changeReady(true); });
    }
    return ready ? <img className={props.cl} src={img}></img> : <></>;
}


export default Blogs;