import React from 'react';

const AddSubQuestionButton = ({addSubQuestion}) => {
  return (
    <>
      <div
        onClick={addSubQuestion}
        className="h-[50px] w-[500px] flex bg-gray-200 rounded-md items-center justify-center ml-[60px]"
      >
        Добавить вопрос к опции +
      </div>
      <div className="h-[10px]"></div>
    </>
  );
};

export default AddSubQuestionButton;