import React from 'react';

export function MagicWidget(props) {
    let o = props.obj;
    return <div className="flex flex-col">
        <div onClick={() => { props.update(); }} className="h-[40px] cursor-pointer w-[200px] items-center flex justify-center text-white rounded-md bg-blue-400">{"Добавлено " + (o["type"] == "photo" ? "фото" : "видео")}</div>
        <div className="text-xs">{o["url"]}</div>
    </div>

}
