import { Button, Divider } from 'antd';
import React, { useState } from 'react';
import Picture from './Picture';
import ApplyOrCancel from '../../../components/ApplyOrCancel';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase.services';

const setInitialGiftProps = (gift) => {
  return {
    id: gift.id,
    name: gift.name,
    description: gift.description,
    fullDescription: gift.fullDescription,
    pictureLinks: gift.pictureLinks,
  }
}

const GiftConfigPanel = ({gift, reloadData}) => {
  const [ giftProps, setGiftProps ] = useState(() => setInitialGiftProps(gift))
  const [ isEdit, setIsEdit ] = useState(false)
  const [ isSaving, setIsSaving ] = useState(false)

  const copyLinks = giftProps.pictureLinks.slice(0);
  copyLinks.shift();

  const makePictureFirst = (index) => {
    const realIndex = index + 1;
    const changedArray = [...giftProps.pictureLinks.slice(realIndex, realIndex + 1), ...giftProps.pictureLinks.slice(0, realIndex), ...giftProps.pictureLinks.slice(realIndex+1)];
    setGiftProps(prev => ({...prev, pictureLinks: changedArray}));
    setIsEdit(true)
  }

  const valueChangeHandler = (e) => {
    setIsEdit(true)
    setGiftProps(prev =>  ({...prev, [e.target.name]:e.target.value}))
  }

  const saveChanges = async () => {
    try {
      setIsSaving(true)
      await updateDoc(doc(db, `gifts/${gift.id}`), {...giftProps})
      reloadData();
      setIsEdit(false)
      console.log('изменения сохранены')
    } catch (error) {
      console.log(error)
    } finally {
      setIsSaving(false)
    }
  }

  const cancelChanges = () => {
    setIsEdit(false)
    setGiftProps(setInitialGiftProps(gift))
  }

  const allPictures = copyLinks.map((downloadURL, index) => {
    return (
      <Picture
        key={index}
        downloadURL={downloadURL}
        makePictureFirst={makePictureFirst}
        index={index}
        giftName={giftProps.name}
      />
    )
  })

  return (
    <div className='flex relative mb-[10px]'>
      <div className=" p-[20px] w-[100%] border-black/[0.3] border-[2px] rounded-md">
        <div className='w-[100%] flex'>
          <div
            style={{position:'relative', flexShrink:'0', paddingBottom:'30%', width:'20%', backgroundColor:"white"}}
          >
            <img
              style={{display:'block', position:'absolute', width:'100%', height:'100%',top:'0', left:'0', objectFit:'contain'}}
              src={giftProps.pictureLinks[0]}
              alt={giftProps.name}
            />
          </div>
          <div className='w-[100%] ml-[3%]'>
            <div className='flex items-center mb-[5px]'>
              <p>Имя: </p>
              <input
                name='name'
                value={giftProps.name}
                className='h-[40px] w-[100%] p-[5px] ml-[10px] bg-white rounded-md '
                onChange={valueChangeHandler}
              />
            </div>
            <div className='flex items-center mb-[5px]'>
              <p>Описание: </p>
              <input
                name='description'
                value={giftProps.description}
                className='h-[40px] w-[100%] p-[5px] ml-[10px] bg-white rounded-md '
                onChange={valueChangeHandler}
              />
            </div>
            <div className='flex items-center mb-[5px]'>
              <p className='shrink-0'>Полное описание: </p>
              <textarea
                name='fullDescription'
                value={giftProps.fullDescription}
                className='h-[300px] w-[100%] p-[5px] ml-[10px] bg-white rounded-md '
                onChange={valueChangeHandler}
              />
            </div>
          </div>
        </div>
        <div className='border border-solid border-slate-300 w-[100%] my-[10px]'></div>
        <div className='flex'>
          {allPictures}
        </div>
      </div>
      <div className='absolute right-[-100px] bottom-0	'>
        {isEdit && <ApplyOrCancel onCancel={cancelChanges} onApply={saveChanges} isSaving={isSaving} orientation='vertical'/>}
        {/* {<ApplyOrCancel onCancel={cancelChanges} onApply={saveChanges} isSaving={isSaving}/>} */}
      </div>
    </div>
  );
};

export default GiftConfigPanel;