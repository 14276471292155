import React from 'react';
import deleteIcon from "../../../../delete.svg";
import EditableText from '../EditableText';

const NestedChoiceOptionBar = ({subOption, subOptionNum, subOptionIndex, deleteOption, changeNestedChoiceOption }) => {
  const handleDeleteNestedChoiceOption = () => {
    deleteOption(subOptionIndex)
  }

  return (
    <>
      <div>
        <div className="flex flex-row items-center">
          <p className='w-[60px] text-rose-800 font-bold'>опция 2ур.</p>
          <div className="h-[50px] w-[500px] bg-white border-[2px] border-black/[0.1] rounded-md flex items-center justify-center gap-[5px]">
            <snap>{subOptionNum}</snap>
            <EditableText 
              value={subOption.option}
              index={subOptionIndex}
              changeFunc={changeNestedChoiceOption}
            />
          </div>
          <div className="w-[20px]"></div>
          <div
            onClick={handleDeleteNestedChoiceOption}
            className="h-[40px] w-[40px] bg-gray-300 rounded-[40px] flex items-center justify-center"
          >
            <img className="h-[20px]" src={deleteIcon}></img>
          </div>
        </div>
      </div>
      <div className="h-[10px]"></div>
    </>
  );
};

export default NestedChoiceOptionBar;
