import React from 'react';
import SubQuestionBar from './SubQuestionBar';
import NestedChoiceOptions from '../options/NestedChoiceOptions';
import AddOptionButton from '../options/AddOptionButton';

const SubQuestion = ({
  country,
  subQuestion,
  sectionIndex,
  questionIndex,
  optionIndex,
  subQuestionIndex,
  updateCountry,
  updateDropOption,
  deleteSubQuestion,
  changeSubQuestion,
}) => {
  const subQuestionNumber = `${sectionIndex + 1}.${questionIndex + 1}-${optionIndex + 1}.${subQuestionIndex + 1}`;
  
  const addOptionOfSubQuestion = () => {
    updateDropOption(prev => {
      return {
        ...prev,
        show: true,
        sectionIndex,
        index: questionIndex,
        nestedChoiceQuestion: true,
        dropdownOptionIndex: optionIndex,
        dropdownSubquestionIndex: subQuestionIndex
      }
    })
  }

  return (
    <>
      <SubQuestionBar
        subQuestion={subQuestion}
        subQuestionIndex={subQuestionIndex}
        subQuestionNumber={subQuestionNumber}
        deleteSubQuestion={deleteSubQuestion}
        changeSubQuestion={changeSubQuestion}
      />
      {subQuestion.type === 'choice' ? ( // если подвопрос типа choice - собираем в массив все опции.
        <div className="pl-[50px] flex flex-col items-start">
          <NestedChoiceOptions
            subQuestion={subQuestion}
            country={country}
            updateCountry={updateCountry}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            optionIndex={optionIndex}
            subQuestionIndex={subQuestionIndex}
          />
          <AddOptionButton
            updateDropOption={updateDropOption}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            addOption={addOptionOfSubQuestion}
          />
        </div>
      ) : (
        null
      )}
    </>
  );
};

export default SubQuestion;