import React, { useEffect, useRef, useState } from 'react';
import deleteIcon from "../../../../delete.svg";
import EditableText from '../EditableText';

const SectionBar = ({
  section,
  sectionIndex,
  expanded,
  expandSection,
  deleteSection,
  changeSectionName
}) => {
  const handleExpand = () => {
    expandSection(sectionIndex);
  }

  const handleDeleteSection = () => {
    deleteSection(sectionIndex);
  }

  const sectionNum = `${sectionIndex + 1}. `
  return (
    <div className="flex flex-row items-center">
      <p className='w-[60px] text-blue-600 font-bold'>секция</p>
      <div className="px-[15px] py-[5px] w-[400px] flex border-[2px] border-black/[0.2]  bg-white rounded-md justify-center items-center">
        <div className="text-lg font-bold  flex justify-center gap-[5px] items-center h-[45px] w-[100%]">
          <span>
            {sectionNum}
          </span>
          <EditableText
            value={section.section}
            index={sectionIndex}
            changeFunc={changeSectionName}
          />
        </div>
      </div>
      <div className="w-[20px]"></div>
      <div
        onClick={handleExpand}
        className="h-[40px] w-[40px] rounded-[40px] cursor-pointer bg-gray-white border-blue-700 border-[2px] flex items-center justify-center font-bold text-blue-600"
      >
        {expanded[sectionIndex] ? "-" : "+"}
      </div>
      <div className="w-[20px]"></div>
      <div
        onClick={handleDeleteSection}
        className="w-[40px] h-[40px] cursor-pointer rounded-[30px] bg-gray-300 flex items-center justify-center"
      >
        <img src={deleteIcon} alt="deleteIcon" className="h-[20px]"></img>
      </div>
    </div>
  );
};

export default SectionBar;
