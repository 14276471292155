import { React, Component } from 'react';

export function BaseCost(props) {
    return <div>
        <div className="">2.2 Оплата посольству</div>
        <div className='h-[20px]'></div>
        <div className="flex flex-row items-center">
            <input onChange={(val) => {
                let clone = structuredClone(props.country);
                clone['fees_USD']['tourist'] = val.currentTarget.value.length != 0 ? parseFloat(val.currentTarget.value) : 0;
                props.update(clone);
            }} value={props.baseCost.toString()} placeholder="За тур. визу" className="h-[40px] text-md w-[300px] bg-white rounded-md pl-[20px]">
            </input>
            <div className="w-[20px]"></div>
            <div className="text-md">KZT</div>
        </div>

    </div>;
}