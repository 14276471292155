import { React, Component } from 'react'
import { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import { Timestamp } from 'firebase/firestore';
import deleteIcon from "../delete.svg"
import dayjs from 'dayjs';


function p(input) {
    return input.toString().padStart(2, "0");
}


export function AvailableAppointments({country, deleteDate, dates, updateCountry}) {
    
    const [pickedDate, changeDate] = useState(null);
    function generateDates() {
        let rets = [];
        for (let i = 0; i < dates.length; i++) {
            // с файрбейза приходят объекты классовые, но в результате функции structuredClone по пути сюда - Timestamp-объекты теряют классовую принадлежность и становятся простыми объектами литералами. Поэтому ничего не остается кроме как брать секунды и умножать на
            let d = dayjs(dates[i].seconds * 1000);
            let retString = d.format('DD/MM/YYYY HH:mm');
            rets.push(
                <div>
                    <div className="flex flex-row items-center">
                        <div className="h-[50px] w-[300px] rounded-md bg-white flex text-md font-bold items-center justify-center">{retString}</div>
                        <div className="w-[20px]"></div>
                        <div onClick={() => {
                            let clone = structuredClone(country);
                            clone['available_appointments']['tourist'].splice(i, 1);
                            deleteDate(clone);
                        }} className="h-[40px] w-[40px] flex items-center justify-center bg-gray-300 rounded-[40px]"><img className="h-[20px]" src={deleteIcon}></img></div>
                    </div>
                    <div className="h-[20px]"></div>
                </div>);
        }
        rets.push(
          <div className="flex flex-row items-center h-[50px]">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={deDE}>
                  <DateTimePicker
                    disabled  // временно заблокировано
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    className="bg-white"
                    onChange={(val) => {changeDate(Timestamp.fromDate(val.toDate())); }}
                  />
              </LocalizationProvider>
              <div className="w-[20px]"></div>
              <div 
                // onClick={() => {
                //     if (pickedDate != null) {
                //         updateCountry(prev => {           
                //           const clone = structuredClone(prev);
                //           clone.available_appointments.tourist.push(pickedDate);  // временно заблокировано
                //           return clone
                //         })
                //         changeDate(null)
                //     }
                // }} 
                className="w-[100px] h-[50px] bg-gray-300 flex items-center justify-center rounded-md"
              >
                Добавить
              </div>
          </div>
        );
        return rets;
    }


    return <div className="flex flex-col justify">
        <div className="text-xl">2.1 Доступные даты для интервью</div>
        <div className="h-[10px]"></div>
        {generateDates()}
    </div>;
}
