import React from 'react';
import closeIcon from "../close.svg"

export function renderSectionPicker(changeSectionAdderState, changeSectionText, country, updateCountry, newSectionText) {
    return <div className="top-[40%] left-[10%] h-[200px] w-[80%] bg-white rounded-md self-center absolute overflow-x-hidden flex flex-col items-start p-[20px]">
      <div className="flex w-[100%] flex-row justify-between">
        <p className="text-xl font-bold">Имя новой секции</p>
        <img onClick={() => { changeSectionAdderState(false); }} alt="closeIcon" className="h-[30px]" src={closeIcon}></img>
      </div>
      <div className="h-[10px]"></div>
      <input onChange={(val) => {
        changeSectionText(val.target.value);
      }} placeholder='Добавьте название новой секции'
        className="h-[50px] w-[100%] pl-[20px] rounded-md border-black/[0.1] border-[2px]"></input>
      <div className="h-[20px]"></div>
      <div onClick={() => {
        if (newSectionText.length > 0) {
          let clone = structuredClone(country);
          clone['questionnaires'][0]['sections'].push({
            'section': newSectionText,
            'questions': [],
          });
          updateCountry(clone);
          changeSectionAdderState(false);
        }
      }} className="h-[50px] w-[100%] rounded-md bg-blue-600 flex items-center justify-center text-white">Добавить</div>
    </div>;
  }