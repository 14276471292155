import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase.services";
import { gotMiniCountry, updateGotMiniCountry, miniCountryDoc, updateMiniCountryDoc } from "../variables";

export async function getMiniCountry(updateCountries) {
    // Проверяем, была ли уже получена информация о мини-документе стран
    if (!gotMiniCountry) {
        // Получаем мини-документ стран из Firestore
        updateMiniCountryDoc(await getDoc(doc(db, "countries-mini", "all-countries")));

        // Получаем массив стран из мини-документа
        const miniCountries = miniCountryDoc.data().countries;

        // Инициализируем массив с опциями для отображения в выпадающем списке
        const renderOptions = [<option value="США">США</option>,];

        // Добавляем опции для каждой страны в массив
        miniCountries.forEach(country => {
            if (country.name_ru !== 'США') {
                renderOptions.push(<option value={country.name_ru}>{country.name_ru}</option>)
            }
        })

        // Сортируем опции в алфавитном порядке
        renderOptions.sort((optionA, optionB) => {
            if (optionA.props.value.toLowerCase() > optionB.props.value.toLowerCase()) return 1;
            if (optionA.props.value.toLowerCase() < optionB.props.value.toLowerCase()) return -1;
            return 0;
        })

        // Обновляем состояние компонента с опциями стран
        updateCountries(renderOptions);

        // Устанавливаем флаг получения мини-документа стран в true
        updateGotMiniCountry(true)
    }
}