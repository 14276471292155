export const validationErrorMessages = {
  requiredField: "Поле должно быть заполнено",
  passwordMinLength: 'Пароль должен содержать минимум 6 символов',
  passwordMaxLength: "Пароль должен содержать не более 16 символов",
  emailWrong: "неверный E-mail адрес",
}


export const fieldRules = {
  email:[
    {
      validateTrigger:"onSubmit",
      required:true,
      message:validationErrorMessages.requiredField,
    },
    {
      validateTrigger:"onSubmit",
      type:"email",
      message:validationErrorMessages.emailWrong,
    }
  ],
  password:[
    {
      validateTrigger:"onSubmit",
      required: true,
      message:validationErrorMessages.requiredField,
    },
  ],
}