import { React, Component } from 'react'

export function USDConversion(props) {
  return (
    <div>
      {/* <div className="">1.5 Курс валюты этой страны к курсу доллара США</div> */}
      <div className="">2.5 Курс KZT к курсу валюты этой страны</div>
      <div className="h-[20px]"></div>
      <div className="flex flex-row items-center">
        <input
          placeholder="Конвертация 1$ в местную валюту"
          onChange={(val) => {
            let clone = structuredClone(props.country);
            clone['usd_conversion'] = val.currentTarget.value;
            props.update(clone);
          }}
          value={props.country['usd_conversion'].toString()}
          className="h-[40px] w-[300px] rounded-md pl-[20px]"
        />
        <div className="w-[20px]"></div>
        <div className="text-md">{`= 1 ${props.country.currency_code}`}</div>
      </div>
    </div>
  )
}