import { Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import React, { useEffect } from 'react';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import createFileRef from '../../../firebase/createFileRef';
// import '../../../assets/gifts/gift-preview.scss';

const GiftPreview = ({gift, selectGift, isChosen}) => {
  const chooseGiftHandler = () => {
    selectGift(gift.id )
  }

  return (
    <div onClick={chooseGiftHandler}
      style={{
        width:'18.4%',
      }}
    >
      <Card
        className={`rounded-none ${isChosen && 'shadow-[0_0_4px_1px_blue]'}`}
        cover={
          <div
            style={{position:'relative', paddingBottom:'100%'}}
          >
            <img
              alt={gift.name}
              src={gift.pictureLinks[0]}
              style={{position:'absolute', width:'100%', height:'100%', objectFit:'contain', top:'0', left:'0'}}
            />
          </div>
        }
      >
        <Meta
          title={gift.name}
          description={gift.description}
        />
      </Card>
    </div>
  );
};

export default GiftPreview;
