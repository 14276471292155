import { doc, getDoc } from 'firebase/firestore';
import { db, storage } from '../firebase/firebase.services';
import { ref, getDownloadURL} from 'firebase/storage';
import { previewUrl, flagUrl, gotCountry, updateGotCountry, updatePreviewUrl, updateFlagUrl} from '../variables';


export async function getCountry(name, c, c_eng, cDocData, updateCountry, changeDocLoadState, updateMakingChanges) {
  console.log('123')
  console.log(gotCountry)
    // Проверяем, была ли уже получена информация о стране
    if (!gotCountry) {
      // Устанавливаем флаг получения информации о стране в true
      updateGotCountry(true);

      // Находим индекс страны в массиве по её русскому названию
      let index = c.findIndex((ruCountryName) => ruCountryName === name);

      // Формируем имя документа в Firestore на основе английского названия страны
      let docName = c_eng[index].toLowerCase();

      // Получаем документ страны из Firestore
      let countryDoc = await getDoc(doc(db, "countries", docName));

      // Проверяем, существует ли документ страны
      if (countryDoc.exists()) {
        // Клонируем данные документа
        cDocData = structuredClone(countryDoc.data());

        // Получаем URL для превью изображения, если оно существует
        if (cDocData?.images?.preview) {
            updatePreviewUrl(await getDownloadURL(ref(storage, cDocData.images.preview)));
        }

        // Получаем URL для флага, если он существует
        updateFlagUrl(cDocData?.images?.flag && await getDownloadURL(ref(storage, cDocData.images.flag)));

        // Обновляем состояние компонента с данными о стране
        updateCountry(cDocData);

        // Выводим в консоль сообщение о изменении состояния загрузки документа
        console.log('changeDocLoadState');

        // Устанавливаем состояние загрузки документа в true
        changeDocLoadState(true);

        // Устанавливаем состояние "идет процесс внесения изменений" в false
        updateMakingChanges(false);
      } else {
        // Если документ страны не существует, выводим сообщение в консоль и завершаем процесс
        console.log('changeDocLoadState');

        // Устанавливаем состояние загрузки документа в true
        changeDocLoadState(true);

        // Устанавливаем состояние "идет процесс внесения изменений" в false
        updateMakingChanges(false);
      }
    }
  }