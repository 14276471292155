import React from 'react';
import SectionBar from './SectionBar';
import Questions from '../questions/Questions';
import AddQuestionButton from '../questions/AddQuestionButton';
import Section from './Section';

const Sections = ({
  sections,
  country,
  updateCountry,
  expanded,
  changeExpanded,
  updateQuestionObj,
  changeQuestionState,
  optionQuestion,
  updateDropOption,
  updateOptionQuestion,
}) => {
  const expandSection = (sectionIndex) => {
    changeExpanded(prev => {
      let exp = structuredClone(prev);
      exp[sectionIndex] = !exp[sectionIndex];
      return exp;
    })
  }

  const deleteSection = (sectionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections.splice(sectionIndex, 1);
    updateCountry(clone);
  }

  const changeSectionName = (event, sectionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].section = event.target.value;
    updateCountry(clone);
  }

  return sections.map((section, sectionIndex) => {
    return (
      <Section 
        section={section}
        sectionIndex={sectionIndex}
        expanded={expanded}
        expandSection={expandSection}
        deleteSection={deleteSection}
        changeSectionName={changeSectionName}
        country={country}
        updateCountry={updateCountry}
        optionQuestion={optionQuestion}
        updateDropOption={updateDropOption}
        updateOptionQuestion={updateOptionQuestion}
        updateQuestionObj={updateQuestionObj}
        changeQuestionState={changeQuestionState}
      />
    )
  });
};

export default Sections;