import {React, useState} from "react";
import closeIcon from "../close.svg"


export function MagicPicker(props) {
    const [pick, changePick] = useState(0);
    const [url, changeUrl] = useState("");
    return <div className="h-[250px] w-[400px] ml-[calc(50%-200px)] mt-[calc(50%-150px)] rounded-md relative bg-white self-center flex items-start justify-start flex-col p-[20px]">
      <div className="flex flex-row w-[100%] items-center justify-between">
        <div className="text-bold">Добавить виджет</div>
        <img onClick={() => { props.close(); }} src={closeIcon} className="h-[30px] w-[30px] cursor-pointer"></img>
      </div>
      <div className="h-[20px]"></div>
      <div className="flex flex-row">
        <div onClick={() => { changePick(0) }} className={`h-[40px] w-[100px] ${pick == 0 ? "border-blue-600" : ""} border-[2px] rounded-md flex items-center justify-center`}>Видео</div>
        <div className="w-[20px]"></div>
        <div onClick={() => { changePick(1) }} className={`h-[40px] w-[100px] ${pick == 1 ? "border-blue-600" : ""} border-[2px] rounded-md flex items-center justify-center`}>Фото</div>
      </div>
      <div className="h-[10px]"></div>
      <input onChange={(val) => {
        changeUrl(val.currentTarget.value);
      }} placeholder="Ссылка" className="h-[40px] w-[100%] rounded-md pl-[20px] bg-gray-300"></input>
      <div className="h-[20px]"></div>
      <div onClick={() => {
        if (url != "") {
          let ret = {
            "url": url,
            "type": pick == 0 ? "video" : "photo",
          };
          props.setWid(ret);
        }
      }} className={`h-[40px] w-[100%] rounded-md  bg-blue-600 flex items-center justify-center text-white ${url == "" ? "opacity-30" : "opacity-100"}`}>Добавить виджет</div>
    </div>;
  }