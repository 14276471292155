import { Button, Upload } from 'antd';
import React, { useState } from 'react';
import createFileRef from '../../../firebase/createFileRef';
import { uploadBytesResumable } from 'firebase/storage';
import { PlusOutlined } from '@ant-design/icons';


const getBase64 = (file) =>{
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const transformToBase64 = async (file) => {
  const base64 = await getBase64(file);
  return base64;
}

const UploadPictures = ({countryCode, giftName, setNewGiftObj, pictureList, setPictureList, setFilesUploading}) => {

  const beforeUploadHandler = async (file, fileList) => {
    const base64Preview = await transformToBase64(file);
    setPictureList(prev => {
      return [
        ...prev,
        {
          fileOrigin: file,
          thumbUrl: base64Preview,
          uid: file.uid,
          name: file.name,
        }
      ]
    })

    return Promise.reject() // отмена дефолтной антовской загрузки.
  }

  // const fileDeleteHandler = (file) => {
  //   console.log(file)
  //   const fileRef = createFileRef(`gifts/${countryCode}/${giftName}/${fileInfo.fileOrigin.uid}`);
  // }



  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={pictureList}
        multiple={true}
        beforeUpload={beforeUploadHandler}
        // onRemove={fileDeleteHandler}
        // disabled={filesUploading}
      >
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Добавить
          </div>
        </button>
      </Upload>

    </div>
  );
};

export default UploadPictures;