import React, { useEffect } from 'react';
import loadingIcon from "./loading.gif"
import { c, c_eng, previewUrl, flagUrl, currencies, updateGotMiniCountry, updateGotCountry } from "./variables";
import { useState } from "react";
import { useRef } from 'react';
import { Durations } from './components/durations';
import { BaseCost } from './components/basecost';
import { USDConversion } from './components/conversion';
import _ from 'lodash';
import { AvailableAppointments } from './components/available';
import './index.css';
import { parse } from 'postcss';
import { FAQ } from './components/faq';
import VisaInfo from './modules/VisaInfo/components/VisaInfo';
import { signOut } from 'firebase/auth';
import { auth, db } from './firebase/firebase.services';
import { useNavigate } from 'react-router-dom';
import { renderAdditionalServices } from './widgets/additionalServices';
import { MagicWidget } from './widgets/magicWidget';
import { renderOptionQuestionPicker } from './widgets/optionQuestionPicker';
import { renderOptionPicker } from './widgets/optionPicker';
import { renderServices } from './widgets/services';
import { MagicPicker } from './widgets/magicPicker';
import { renderQuestionPicker } from './widgets/questionPicker';
import { getCountry } from './functions/getCountry';
import { updateDocument } from './functions/updateDocument';
import { renderNewService } from './widgets/newService';
import { renderSectionPicker } from './widgets/sectionPicker';
import { getMiniCountry } from './functions/getMiniCountry';
import { visaTypeRenderer } from './widgets/visaTypeRenderer';
import { differentCountry } from './functions/differentCountry';
import QuestionnaireRules from './components/QuestionnaireRules';
import Questionnaire from './modules/Questionnaire/components/Questionnaire';
import { frontTypes, types } from './variables';
import { visaTypes } from './variables';
import CountryPicker from './modules/CountryPicker/components/CountryPicker';
import ChooseGift from './modules/ChooseGift/components/ChooseGift';
import { collection, getDocs, query, updateDoc, writeBatch } from 'firebase/firestore';
import Faq from './modules/Faq/components/Faq';

let firstTime = true;
let cDocData = {};

function App(props) {
  // console.log(c.findIndex(elem => elem === 'Вьетнам'))
  // console.log(c_eng.findIndex(elem => elem === "Vietnam"))
  // console.log(country_codes.findIndex(elem => elem === "CC"))
  // console.log(currencies.findIndex(elem => elem === "VND"))

  const [countryDocLoaded, changeDocLoadState] = useState(false);
  const [countries, updateCountries] = useState([]); // опции для селекта стран
  const [makingChanges, updateMakingChanges] = useState(false);
  const [expanded, changeExpanded] = useState([]);
  const [showMagic, updateMagic] = useState(false);
  const [whereMagic, showWhereMagic] = useState({ "from": "", "index": -1 });
  const [country, updateCountry] = useState({});
  console.log(country)
  const [fromTopAdd, changeFromTopAdd] = useState(false);
  const [showQuestionAdder, changeQuestionState] = useState(false);
  const [showSectionAdder, changeSectionAdderState] = useState(false);
  const [showCountryPicker, changeCountryPickerState] = useState(false);
  const [showServicePicker, changePickerState] = useState(false);
  const [flagImage, setFlagImage] = useState(null)
  const [flagImageFile, setFlagImageFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [dropOption, updateDropOption] = useState({ // открывает модалку для ввода названия опции, когда нажимаем кнопку "Добавить опцию +", т.к. show меняется на true.
    'show': false,
    'option': "",
    'sectionIndex': 0,
    'index': 0,
  });
  const [previewImageFile, setPreviewImageFile] = useState(null)
  const [newQuestionObj, updateQuestionObj] = useState({
    'isMultiple': false,
    'type': 'text',
    'text': '',
    'sectionIndex': 0,
    'ready': false,
  });
  const [newServiceObj, updateNewService] = useState({
    'show': false,
    'type': 'tourist',
    'name': '',
    'id': '',
    'price_USD': 0,
  });
  const [optionQuestion, updateOptionQuestion] = useState({
    'sectionIndex': 0,
    'questionIndex': 0,
    'optionIndex': 0,
    'question': 0,
    'type': 'text',
    'show': false,
  });

  // useEffect(() => {
  //   const func = async () => {
  //     const coll = await getDocs(query(collection(db, 'countries')));
  //     const batch = writeBatch(db);
  //     coll.forEach(doc => {
  //       batch.update(doc.ref, {chosenGifts: []});
  //     })
  //     await batch.commit();
  //     console.log('батч сохранен')
  //   }
  //   func()
  // }, [])

  const navigate = useNavigate();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFlagImage(URL.createObjectURL(event.target.files[0]));
      setFlagImageFile(event.target.files[0]);
    }
  }

  const onPreviewChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
      setPreviewImageFile(event.target.files[0]);
    }
  }

  const [newSectionText, changeSectionText] = useState('');
  const [visaType, changeVisaType] = useState(0);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const height = windowSize[1];
  const width = windowSize[0];

  const changePage = (route) => {
    navigate(route)
    updateGotMiniCountry(false)
    updateGotCountry(false)
    firstTime = true;
  }

  if (firstTime) {
    getMiniCountry((val) => { updateCountries(val) });
    getCountry(
      'США',
      c,
      c_eng,
      cDocData,
      (val) => { updateCountry(val) },
      (val) => { changeDocLoadState(val) },
      (val) => { updateMakingChanges(val) },
      (val) => { changeDocLoadState(val) }
    );
    firstTime = false;
    console.log('rendering first time');
  }
  console.log('Rendering App!');

  let isChanged = countryDocLoaded ? (!_.isEqual(cDocData, country) || flagImage !== null || previewImage !== null) : false;

  return (
    <div className={`h-[${windowSize.current[1]}px] w-[${windowSize.current[0]}px] overflow-x-hidden`}>
      <div className="flex-col p-5 h-[100%] w-[100%] bg-black/[0.04] items-center absolute top-0 left-0 overflow-y-scroll overflow-x-hidden">
        <div className="flex row items-center justify-center mb-4">
          <p className="text-3xl font-bold ">Vkleyka.kz (Админка для стран)</p>
        </div>
        <div className='flex justify-center flex-row'>
          <button className='rounded-md bg-alert px-4 py-2 font-semibold mb-4' onClick={() => signOut(auth)}>Выйти</button>
          <div className='w-[20px]'></div>
          <div
            className="h-[40px] w-[100px] cursor-pointer flex items-center justify-center text-white rounded-md bg-gray-700"
            onClick={() => changePage('/blogs')}
          >
            Блоги
          </div>
          <div className='w-[20px]'></div>
          <div
            className="h-[40px] w-[100px] cursor-pointer flex items-center justify-center text-white rounded-md bg-gray-700"
            onClick={() => changePage('/gifts')}
          >
            Подарки
          </div>
        </div>
        <div className="w-[600px] m-auto flex justify-center flex-row items-center`}">
          <select
            value={country['name']}
            onChange={(val) => {
              differentCountry(val.target.value, cDocData, (val) => { updateCountry(val) },
                (val) => { changeDocLoadState(val) }, (val) => { updateMakingChanges(val) }); // value - name_ru страны из countries-mini.
            }}
            className='bg-white w-[300px] h-[50px] rounded-md pl-[5px]'
          >
            {countries}
          </select>
          <div className="w-[20px]"></div>
          <div
            onClick={() => {
              changeFromTopAdd(true);
              changeCountryPickerState(true);
            }}
            className="w-[200px] h-[50px] cursor-pointer bg-black/[0.1] rounded-md flex items-center justify-center"
          >
            Добавить страну +
          </div>
          <div className="w-[20px]"></div>
          <div
            onClick={() => {
              if (isChanged) {
                updateDocument((val) => { changeDocLoadState(val); }, (val) => { updateMakingChanges(val) },
                  countryDocLoaded, flagImage, (val) => { setFlagImage(val) }, country,
                  flagImageFile, (val) => { setFlagImageFile(val) }, previewImageFile, (val) => { setPreviewImageFile(val) },
                  previewImage, (val) => { setPreviewImage(val) }, cDocData, (val) => { updateCountry(val) });
              }
            }}
            className={`w-[200px] cursor-pointer h-[50px] bg-blue-600 text-white font-bold rounded-md flex items-center justify-center ${isChanged ? "opacity-100" : "opacity-30"}`}
          >
            Сохранить
          </div>
          <div className="w-[20px]"></div>
          {makingChanges ? <img src={loadingIcon}></img> : <></>}
        </div>
        {countryDocLoaded ?
          <div className="w-full flex flex-col items-center">
            <div className="flex flex-col items-center w-[80%] " id="main-container">
              <div className="h-[20px]"></div>
              <div className="flex flex-row items-center">
                <p className="text-xl">Показывать в приложении</p>
                <div className="w-5"></div>
                <input
                  defaultChecked={country['active']}
                  onChange={(val) => {
                    let clone = structuredClone(country);
                    clone['active'] = val.currentTarget.checked;
                    updateCountry(clone);
                  }}
                  type="checkbox"
                />
              </div>
              <div className="h-[20px]"></div>
              <div className='w-[100%] flex gap-[2%]'>
                <div className="shrink-0 w-[40%] p-[20px] border-black/[0.3] border-[2px] rounded-md">
                  <p className="font-bold text-2xl">1. Изображения страны</p>
                  <div className="h-[10px]"></div>
                  <div className="flex flex-row items-start">
                    <div className="flex flex-col items-center">
                      <p className="text-xl">Картинка флага</p>
                      <div className="h-[10px]"></div>
                      <div onClick={() => {
                        const flagInput = document.getElementById('image-input');
                        flagInput.click();
                      }} className="h-[100px] w-[100px] rounded-[100px] bg-white flex justify-center items-center">
                        {flagImage != null ? <img alt="flag image" className="object-cover h-[100px] w-[100px] rounded-[100px]" src={flagImage} /> : (country['images']['flag'] != "" ?
                          <img className="rounded-[100px] object-cover h-[100px] w-[100px]" src={flagUrl}></img>
                          : <p className="text-3xl font-bold">+</p>)}
                      </div>
                      <input id='image-input' onChange={onImageChange} type="file" className=" hidden cursor-pointer  focus:outline-none">
                      </input>
                    </div>
                    <div className="w-[20px]"></div>
                    <div className="flex flex-col items-center">
                      <p className="text-xl">Картинка предпросмотра</p>
                      <div className="h-[10px]"></div>
                      <div onClick={() => {
                        const previewInput = document.getElementById('preview-input');
                        previewInput.click();
                      }} className="h-[100px] w-[200px] rounded-[10px] bg-white flex items-center justify-center">
                        {previewImage != null ? <img alt="flag image" className="object-cover h-[100px] w-[200px] rounded-[10px]" src={previewImage} /> :
                          (country['images']['preview'] != "" ?
                            <img className="rounded-md object-cover h-[100px] w-[200px]" src={previewUrl}></img> :
                            <p className="text-3xl font-bold">+</p>)}
                        <input id='preview-input' onChange={onPreviewChange} type="file" className=" hidden cursor-pointer  focus:outline-none"></input>
                      </div>
                    </div>
                  </div>
                </div>
                <ChooseGift chosenGifts={country.chosenGifts} updateCountry={updateCountry} country={country} />
              </div>
              <div className="h-[20px]" />
              <div className="h-[20px]" />
              <VisaInfo
                visaInfo={country.visaInfo}
                updateCountry={updateCountry}
              />
              <div className="h-[20px]"></div>

              <div className='flex  justify-evenly w-[100%]'>
                <div className="p-[20px] rounded-md border-black/[0.3] border-[2px]">
                  <AvailableAppointments
                    country={country}
                    deleteDate={(clone) => {
                      updateCountry(clone);
                    }}
                    dates={country['available_appointments']['tourist']}
                    updateCountry={updateCountry}
                  />
                  <div className="h-[20px]"></div>
                  <BaseCost country={country} update={(val) => { updateCountry(val); }} baseCost={country['fees_USD']['tourist']}></BaseCost>
                  <div className="h-[20px]"></div>
                  <Durations country={country} update={(val) => { updateCountry(val); }}></Durations>
                  <div className="h-[20px]"></div>
                  <USDConversion country={country} update={(val) => { updateCountry(val); }}></USDConversion>
                </div>

                <div className="p-[20px] rounded-md border-black/[0.3] border-[2px]">
                  <p className="font-bold text-2xl">3. Контактная информация</p>
                  <div className="h-[15px]"></div>
                  <select className="w-[300px] h-[50px] pl-[10px] rounded-md">
                    <option value="usa">Представительство в Казахстане</option>
                  </select>
                  <div className="h-[15px]"></div>
                  <div className="flex flex-col items-start">
                    <div className="flex flex-row items-center">
                      <p className="text-md">1. Адрес</p>
                      <div className="w-[10px]"></div>
                      <textarea
                        defaultValue={country['contact_info']['address']}
                        onChange={(val) => {
                          const clone = structuredClone(country);
                          clone['contact_info']['address'] = val.target.value;
                          updateCountry(clone);
                        }}
                        placeholder="Введите адрес"
                        className="h-[70px] w-[300px] pl-[10px] rounded-md"
                      />
                    </div>
                    <div className="h-[15px]"></div>
                    <div className="flex flex-row items-center">
                      <p className="text-md">2. Номер телефона</p>
                      <div className="w-[10px]"></div>
                      <textarea
                        defaultValue={country['contact_info']['phone']}
                        onChange={(val) => {
                          const clone = structuredClone(country);
                          clone['contact_info']['phone'] = val.target.value;
                          updateCountry(clone);
                        }}
                        placeholder="Введите номер"
                        className="h-[70px] w-[300px] pl-[10px] rounded-md"
                      />
                    </div>
                    <div className="h-[15px]"></div>
                    <div className="flex flex-row items-center">
                      <p className="text-md">3. Время работы</p>
                      <div className="w-[10px]"></div>
                      <textarea
                        defaultValue={country['contact_info']['time']}
                        onChange={(val) => {
                          const clone = structuredClone(country);
                          clone['contact_info']['time'] = val.target.value;
                          updateCountry(clone);
                        }}
                        placeholder="Введите время работы"
                        className="h-[100px] w-[300px] pl-[10px] rounded-md"
                      />
                    </div>
                    <div className="h-[20px]"></div>
                    {
                      country["contact_info"]["magic"] == null ? <div onClick={() => {
                        showWhereMagic({
                          "from": "contact_info",
                          "index": -1,
                        });
                        updateMagic(true);
                      }} className="h-[40px] w-[100px] text-md flex items-center cursor-pointer justify-center text-blue-500 rounded-md border-blue-500 border-[2px]">Виджет +</div> :
                        <MagicWidget obj={country["contact_info"]["magic"]} update={() => {
                          showWhereMagic({
                            "from": "contact_info",
                            "index": -1,
                          });
                          updateMagic(true);
                        }} />}
                  </div>
                </div>
              </div>

              <div className="h-[20px]"></div>

              <div className="p-[20px] rounded-md border-black/[0.3] border-[2px] self-center w-[70%]">
                <p className="font-bold text-2xl text-center">4. Услуги Vkleyka</p>
                {renderServices(countryDocLoaded, country, (val) => { updateCountry(val) }, newServiceObj, (val) => { updateNewService(val) })}
              </div>

              <div className="h-[20px]"></div>

              <div className="p-[20px] rounded-md border-black/[0.3] border-[2px] w-[70%] self-center">
                <div className="text-2xl font-bold text-center">5. Дополнительные услуги</div>
                {renderAdditionalServices(country, (val) => { updateCountry(val) },
                  (val) => { updateMagic(val) },
                  (i) => {
                    showWhereMagic({
                      "from": "additional_services",
                      "index": i,
                    })
                  }
                )}
              </div>

              <div className="h-[10px]"></div>
              <Faq country={country} updateCountry={updateCountry}/>
              {/* <FAQ faq={country.faq} update={(val) => {
                let clone = structuredClone(country);
                clone["faq"] = val;
                updateCountry(clone);
              }} /> */}

              <div className="h-[20px]"></div>

              <div className="p-[20px] rounded-md border-black/[0.3] border-[2px] w-[100%]">
                <p className="font-bold text-2xl text-center">7. Анкета</p>
                <div className="h-[15px]"></div>
                <select className="block w-[300px] h-[50px] pl-[10px] rounded-md mx-auto">
                  <option value="usa">Туристическая</option>
                  <option value="canada">Рабочая</option>
                  <option value="mexico">Студенческая</option>
                </select>
                <div className="h-[20px]"></div>
                <QuestionnaireRules
                  filling_rules={country["filling_rules"]}
                  update={(val) => {
                    let clone = structuredClone(country);
                    clone['filling_rules'] = val;
                    updateCountry(clone);
                  }}
                />
                <div className="h-[20px]"></div>
                <Questionnaire
                  country={country}
                  expanded={expanded}
                  optionQuestion={optionQuestion}
                  changeExpanded={changeExpanded}
                  updateCountry={updateCountry}
                  updateDropOption={updateDropOption}
                  updateQuestionObj={updateQuestionObj}
                  updateOptionQuestion={updateOptionQuestion}
                  changeQuestionState={changeQuestionState}
                  changeSectionAdderState={changeSectionAdderState}
                />
              </div>
              <div className="h-[20px]"></div>
              <p className="font-bold text-2xl">8. Отношения с другими странами</p>
              <div className="h-[15px]"></div>
              <div className="flex flex-row items-start">
                {visaTypeRenderer(visaTypes, (val) => { changeVisaType(val) }, visaType)}
              </div>
              <div className="h-[20px]"></div>
              <div className="flex flex-wrap gap-[20px]">
                {/* {renderCountries(visaTypes, visaType, country, (val) => { updateCountry(val) })}   // временно заблокировано*/}
                <div
                  // onClick={() => {
                  //   changeFromTopAdd(false);
                  //   changeCountryPickerState(true);  // временно заблокировано
                  // }}
                  className="h-[50px] w-[250px] bg-black/[0.1] rounded-md flex items-center justify-center text-md">Добавить +</div>
              </div>
            </div>
          </div>
          :
          <img className="pt-[20px] m-auto self-center" src={loadingIcon}></img>
        }
      </div>
      {/* // затемнение */}
      {renderBlackCover()}
      {/* Выбор типа вопросов */}
      {showQuestionAdder ? renderQuestionPicker((val) => { changeQuestionState(val) },
        (val) => { updateQuestionObj(val) },
        newQuestionObj, country, (val) => { updateCountry(val) }, types, frontTypes) : <></>}
      {/* // Выбор новой страны */}
      {showCountryPicker && (
        <CountryPicker
          ruCountries={c}
          fromTopAdd={fromTopAdd}
          country={country}
          countries={countries}
          visaType={visaType}
          updateCountries={updateCountries}
          updateCountry={updateCountry}
          changeCountryPickerState={changeCountryPickerState}
          differentCountry={(val) => {
            differentCountry(
              val,
              cDocData,
              (val) => { updateCountry(val) },
              (val) => { changeDocLoadState(val) },
              (val) => { updateMakingChanges(val) }
            )
          }}
        />
      )}
      {/* {showCountryPicker ? renderCountryPicker(
        c,
        fromTopAdd,
        visaTypes,
        country,
        countries,
        (val) => { updateCountries(val) },
        (val) => { updateCountry(val) },
        (val) => { changeCountryPickerState(val) },
        visaType,
        (val) => {
          differentCountry(
            val,
            cDocData,
            (val) => { updateCountry(val) },
            (val) => { changeDocLoadState(val) },
            (val) => { updateMakingChanges(val) }
          )
        }
      ) : <></>} */}
      {/* // Выбор секции */}
      {showSectionAdder ? renderSectionPicker((val) => { changeSectionAdderState(val) },
        (val) => { changeSectionText(val) }, country, (val) => { updateCountry(val) }, newSectionText) : <></>}
      {/* // Новая услуга */}
      {newServiceObj['show'] ? renderNewService(newServiceObj, (val) => { updateNewService(val) }, country, (val) => { updateCountry(val) }) : <></>}
      {/* // Выбор опции */}
      {dropOption['show'] ? renderOptionPicker((val) => { updateDropOption(val) }, dropOption, country, (val) => { updateCountry(val) }) : <></>}
      {/* // Выбор опций для вопросов */}
      {optionQuestion['show'] ? renderOptionQuestionPicker(types, (val) => { updateOptionQuestion(val); }, optionQuestion, country, (val) => { updateCountry(val); }, frontTypes) : <></>}
      {/* // Магический виджет */}
      {showMagic ? <MagicPicker setWid={(val) => {
        let c = structuredClone(country);
        if (whereMagic["index"] == -1) {
          c[whereMagic["from"]]["magic"] = val;
        } else {
          c[whereMagic["from"]][whereMagic["index"]]["magic"] = val;
        }
        updateCountry(c);
        ; updateMagic(false);
      }} close={() => { updateMagic(false); }} /> : <></>}
    </div>
  );

  function renderBlackCover() {
    return showServicePicker ||
      showCountryPicker ||
      showQuestionAdder ||
      showSectionAdder ||
      newServiceObj['show'] ||
      dropOption['show'] ||
      optionQuestion['show'] ||
      showMagic ?
      <div onClick={() => {
        let clone = structuredClone(newServiceObj);
        clone['show'] = false;
        updateNewService(clone);
        changeCountryPickerState(false);
        changePickerState(false);
        changeSectionAdderState(false);
        changeQuestionState(false);
        updateMagic(false);
      }} className="absolute top-0 left-0 h-[100%] w-[100%] bg-black/[0.5] overflow-y-hidden overflow-x-hidden"></div> : <div className="h-0 w-0"></div>;
  }



}

export default App;


