import { signOut } from 'firebase/auth';
import React from 'react';
import { auth } from '../firebase/firebase.services';

const AccessDenied = () => {
  const handleSignOut = () => {
    signOut(auth)
  }

  return (
    <div style={{width:'100vw', height: '100vh', display:'flex', justifyContent:'center', marginTop:'10%'}}>
      <div style={{padding:'30px'}}>
        <div style={{color:'black', fontSize:'24px', fontFamily:'sans-serif'}}>
          <p className='mb-8 text-alert font-semibold' >Доступ запрещен!</p>
          <button className='rounded-md bg-primary px-4 py-2 font-semibold mb-4' onClick={handleSignOut}>Выйти</button>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
