import React from "react";
import closeIcon from "../close.svg"
import { translFunc } from "../variables";
import { nanoid } from "nanoid";

export function renderNewService(newServiceObj, updateNewService, country, updateCountry) {
    let isDone = newServiceObj['name'] != "" && newServiceObj['price_USD'] != "";

    return (
      <div className="top-[20%] left-[10%] h-[450px] w-[80%] bg-white rounded-md self-center absolute flex flex-col items-start p-[20px]">
        <div className="flex flex-row justify-between w-[100%] items-center">
          <p className="text-xl font-bold">Добавить новую услугу</p>
          <img onClick={() => {
            let clone = structuredClone(newServiceObj);
            clone['show'] = false;
            updateNewService(clone);
          }}
            className="h-[30px]" alt="closeIcon" src={closeIcon}></img>
        </div>
        <div className="h-[20px]"></div>
        <select className="h-[50px] w-[200px] pl-[10px] border-[2px] border-black/[0.1] rounded-md">
          <option>Туристическая виза</option>
        </select>
        <div className="h-[20px]"></div>
        <p className="text-md font-bold">Название</p>
        <div className="h-[20px]"></div>
        <input
          onChange={(val) => {
            let clone = structuredClone(newServiceObj);
            clone['name'] = val.target.value;
            // clone['id'] = nanoid();
            updateNewService(clone);
          }}
          placeholder="Например: 5 Дней"
          className="h-[50px] w-[300px] rounded-md border-[2px] pl-[10px]"
        />
        <div className="h-[20px]"></div>
        <p className="text-md font-bold">Стоимость в долларах США</p>
        <div className="h-[20px]"></div>
        <div className="flex flex-row items-center">
          <input onChange={(val) => {
            let clone = structuredClone(newServiceObj);
            clone['price_USD'] = val.target.value;
            updateNewService(clone);
          }} placeholder="Например: 49"
            className="h-[50px] w-[300px] rounded-md border-[2px] pl-[10px]"></input>
          <div className="w-[20px]"></div>
          <div className="text-xl font-bold">$</div>
        </div>
        <div className="h-[20px]"></div>
        <div onClick={(val) => {
          if (isDone) {
            let countryClone = structuredClone(country);
            countryClone.services.push(
              {
                'name': newServiceObj['name'],
                'price_USD': newServiceObj['price_USD'],
                'type': newServiceObj['type'],
                'id': nanoid(), // генерация нового айди для нового сервиса при сохранении добавлении объекта в страну
              }
            );
            let seerviceObjClone = structuredClone(newServiceObj);
            seerviceObjClone['show'] = false;
            updateCountry(countryClone);
            updateNewService(seerviceObjClone);
          }
        }} className="h-[50px] w-[100%] bg-blue-600 font-bold rounded-md text-white flex items-center justify-center">
          Добавить новую услугу
        </div>
      </div>
    )
  }