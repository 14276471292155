import { getDownloadURL } from "firebase/storage";
import createFileRef from "./createFileRef";

const getUploadedFileDownloadURL = async (path) => {
  console.log('зашли в получение ссылки')
  const downloadURL = await getDownloadURL(createFileRef(path))
  console.log('downloadURL: ', downloadURL )

  return downloadURL
};

export default getUploadedFileDownloadURL;