import React from "react"
import deleteIcon from "../delete.svg"
import MyEditor from "../CKeditor/components/MyEditor";

export function renderServices(countryDocLoaded, country, updateCountry, newServiceObj, updateNewService) {
    let renderedServices = [];
    if (countryDocLoaded) {
        for (let i = 0; i < country['services'].length; i++) {
            let obj = country['services'][i];
            renderedServices.push(<div key={i + "obj"}>
                <div className="flex flex-row items-center">
                    <div className="h-[50px] w-[100%] flex flex-row justify-between items-center pl-[10px] pr-[10px] bg-white rounded-md">
                        <input
                          onChange={(val) => {
                            let clone = structuredClone(country);
                            clone.services[i].name = val.target.value;
                            updateCountry(clone);
                          }}
                          className="h-[50px] w-[70%] rounded-md  pl-[10px]"
                          value={obj.name !== undefined ? obj.name : ""}
                        />
                        <div className='flex flex-row justify-end items-center'>
                          <input
                            onChange={(val) => {
                              let clone = structuredClone(country);
                              if (!Number.isFinite(+val.target.value) || val.target.value.split('').includes('.')) return; // запрещаем печатать не цифры и точку, т.к. дробных чисел быть не должно
                              let p = val.target.value.trim();// нельзя содержать пробелы в середине цены
                              if(p.split('')[0] === '0') { // убираем 0, когда начинаем писать цену.
                                clone.services[i].price_USD = p.split('').slice(1).join('');
                                updateCountry(clone);
                                return;
                              }
                              if (p !== '') { // нельзя сохранять пустую строку в БД.
                                clone.services[i].price_USD = p;
                              } else {
                                clone.services[i].price_USD = 0; // зписываем 0 вместо пустой строки.
                              }
                              updateCountry(clone);
                            }}
                            value={obj.price_USD !== undefined ? obj.price_USD : ""}
                            className="grow-0 w-[50%] h-[50px] rounded-md text-md font-bold"
                          />
                          <span className='text-md font-bold'>KZT</span>
                        </div>
                    </div>
                    <div className="w-[20px]"></div>
                    <div
                      onClick={() => {
                        let cloneServices = structuredClone(country);
                        cloneServices['services'].splice(i, 1);
                        updateCountry(cloneServices);
                      }}
                      className="w-[40px] h-[40px] rounded-[30px] bg-gray-300 flex items-center justify-center"
                    >
                      <img src={deleteIcon} alt="deleteIcon" className="h-[20px]"></img>
                    </div>
                </div>
                <div className="h-[10px]"></div>
                <MyEditor
                  initialData={obj.description !== undefined ? obj.description : ''}
                  changeText={(editor) => {
                    let clone = structuredClone(country);
                    clone.services[i].description = editor.getData();
                    updateCountry(clone);
                  }}
                />
                {/* <textarea
                  onChange={(val) => {
                    let clone = structuredClone(country);
                    clone["services"][i]["description"] = val.currentTarget.value;
                    updateCountry(clone);
                  }}
                  value={obj["description"] != undefined ? obj["description"] : ""}
                  placeholder="Описание скорости услуги"
                  className="block min-h-[100px] h-[100%] pl-[20px] w-[100%] bg-gray-300 rounded-md"
                /> */}
                <div className="h-[20px]"></div>

            </div>
            );
        }
        return <div>
            {renderedServices}
            <div
              onClick={() => {
                let clone = structuredClone(newServiceObj);
                clone['show'] = true;
                updateNewService(clone);
              }}
              className="h-[50px] w-[330px] flex flex-row text-md justify-center space-x-20 items-center mx-auto pl-[10px] pr-[10px] bg-black/[0.1] rounded-md"
            >
                Добавить +
            </div>
        </div>;
    } else {
        return <></>;
    }
}