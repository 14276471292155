import React from 'react';

const NewQuestionButton = ({country, updateCountry}) => {
  return (
    <div>
      <div
        onClick={() => {
          let clone = structuredClone(country);
          clone.faq.push({"question": "", "answer": ""});
          updateCountry(clone);
        }}
        className="h-[40px] cursor-pointer w-[200px] rounded-md bg-gray-300 flex items-center justify-center self-center"
      >
        Добавить вопрос
      </div>
    </div>
  );
};

export default NewQuestionButton;