import React from 'react';
import loadingIcon from "../loading.gif";

const ApplyOrCancel = ({onCancel, onApply, isSaving, orientation='horizontal'}) => {
  console.log(orientation)
  console.log(`${orientation === 'horizontal' ? 'flex items-center' : 'block'}`)
  const cancelHandler = () => {
    onCancel()
  }
  const applyHandler = () => {
    onApply()
  }
  return (
    <div className={` ${orientation === 'horizontal' ? 'flex items-center' : 'block'}`}>
      <button
        className={`px-[10px] py-[5px] text-sm border border-black/[0.3] rounded-md ${orientation === 'horizontal' ? 'mb-[0]' : 'mb-[5px]'}`}
        onClick={cancelHandler}
      >
        Отмена
      </button>
      <div className='relative flex items-center'>
        <button
          className={`rounded-md px-[10px] py-[5px] text-sm	bg-blue-600	text-white active:bg-blue-500	active:shadow-sm	active:shadow-blue-500/50 ${orientation === 'horizontal' ? 'ml-[5px]' : 'ml-[0]'}`}
          onClick={applyHandler}
        >
          Сохранить
        </button>
        {isSaving && <img className='absolute block ml-[3px] w-[15px] h-[15px] right-[-20px]' src={loadingIcon}></img>}
      </div>
    </div>
  );
};

export default ApplyOrCancel;