const sortRuCountries = (ruCountries) => {
  // функция сортировки меняет массив на месте. Если не сделать копию, то оригинальный массив русских стран изменит свой порядок и это сломает все.
  const copy = [...ruCountries];
  return copy.sort((a, b) => {
    const loweredA = a.toLowerCase()
    const loweredB = b.toLowerCase()
    if(loweredA > loweredB) {
      return 1
    }
    if(loweredA < loweredB) {
      return -1
    }
    return 0
  });
}

export default sortRuCountries;