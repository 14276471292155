import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db, storage } from '../firebase/firebase.services';
import { ref, uploadBytes } from 'firebase/storage';
import { gotCountry, updateGotCountry, country_codes, c, c_eng, miniCountryDoc, updateMiniCountryDoc } from '../variables';
import { getCountry } from './getCountry';

export async function updateDocument(changeDocLoadState, updateMakingChanges,
    countryDocLoaded, flagImage, setFlagImage, country,
    flagImageFile, setFlagImageFile, previewImageFile, setPreviewImageFile, previewImage, setPreviewImage, cDocData, updateCountry) {
    // Устанавливаем состояние загрузки документа в false
    changeDocLoadState(false);

    // Находим индекс страны в массиве
    console.log(country)
    let ind = c.findIndex((e) => e == country['name']);

    // Формируем имя документа в Firestore на основе английского названия страны
    let docName = c_eng[ind].toLowerCase();

    // Устанавливаем флаг "идет процесс внесения изменений" в true
    updateMakingChanges(true);

    // Проверяем, загружен ли документ страны
    if (countryDocLoaded) {
        // Обработка изображения флага
        if (flagImage !== null) {
            const storageRef = ref(storage, `mini_flags/${country_codes[ind].toLowerCase()}-small.png`);
            await uploadBytes(storageRef, flagImageFile).then((snapshot) => {
            });
            setFlagImage(null);
            setFlagImageFile(null);
            country['images']['flag'] = `mini_flags/${country_codes[ind].toLowerCase()}-small.png`;
        }

        // Обработка превью изображения
        if (previewImage != null) {
            const storageRef = ref(storage, `country_images/${country_codes[ind].toLowerCase()}.png`);
            await uploadBytes(storageRef, previewImageFile).then((snapshot) => {
            });
            setPreviewImage(null);
            setPreviewImageFile(null);
            country['images']['preview'] = `country_images/${country_codes[ind].toLowerCase()}.png`;
        }

        // Получаем мини-документ страны
        updateMiniCountryDoc(await getDoc(doc(db, "countries-mini", "all-countries")));
        let cs = structuredClone(miniCountryDoc.data());
        
        // Находим индекс страны в мини-документе
        let miniIndex = cs['countries'].findIndex((e) => e['name_eng'] === c_eng[ind]);
        console.log('miniIndex:', miniIndex)
        // Обновляем или добавляем информацию о стране в мини-документе
        if (miniIndex !== -1) {
            console.log('miniIndex:', miniIndex)
            cs['countries'][miniIndex] = {
                'name_ru': c[ind],
                'name_eng': c_eng[ind],
                'active': country['active'],
                'flag': country['images']['flag'],
                'flag_local': miniCountryDoc.data().countries[miniIndex].flag_local || '',
                'country_code': country_codes[ind].toLowerCase(),
            };
        } else {
            console.log('miniIndex:', miniIndex)
            cs['countries'].push({
                'name_ru': c[ind],
                'name_eng': c_eng[ind],
                'active': country['active'],
                'flag': country['images']['flag'],
                'flag_local':'',
                'country_code': country_codes[ind].toLowerCase(),
            });
        }

        // Клонируем объект страны для обновления документа в Firestore
        let clone = structuredClone(country);


        // Преобразуем даты в объекте "available_appointments"
        // в результате функции structureClone, Timestamp-объекты, пока шли сюда,  потеряли принадлежность к классу и стали простыми литерал объектами. Такие объекты файрбейз не примет, поэтому превращаем их обратно в Timestamp-объекты
        for (let b = 0; b < clone["available_appointments"]['tourist'].length; b++) {
            let obj = clone["available_appointments"]['tourist'][b];
            clone['available_appointments']["tourist"][b] = Timestamp.fromMillis(obj['seconds'] * 1000);
        }
        console.log(cs['countries'][miniIndex])
        // Обновляем мини-документ стран в Firestore
        await setDoc(doc(db, "countries-mini", "all-countries"), cs);

        // Выводим в консоль информацию о секции страны
        console.log(clone.questionnaires[0].sections[1]);

        // Обновляем документ страны в Firestore
        await setDoc(doc(db, "countries", docName), clone).then((e) => {
            updateGotCountry(false);
            getCountry(country['name'], c, c_eng, cDocData, (val) => { updateCountry(val) },
                (val) => { changeDocLoadState(val) }, (val) => { updateMakingChanges(val) }, (val) => { changeDocLoadState(val) });
        });
    }
}