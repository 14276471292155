import React from "react";
import closeIcon from "../close.svg"
import { questionTypes } from "./questionTypes";



export function renderQuestionPicker(changeQuestionState, updateQuestionObj, newQuestionObj, country, updateCountry, types, frontTypes) {
    return (
        <div className="top-[20%] left-[10%] h-[500px] w-[80%] bg-white rounded-md self-center absolute overflow-x-hidden flex flex-col items-start p-[20px]">
            <div className="flex w-[100%] flex-row justify-between">
                <p className="text-xl font-bold">Выберите тип вопроса</p>
                <img onClick={() => { changeQuestionState(false); }} alt="closeIcon" src={closeIcon} className='h-[30px]'></img>
            </div>
            <div className="h-[20px]"></div>
            <div className="flex flex-wrap gap-[20px]">
                {questionTypes(types, updateQuestionObj, frontTypes, newQuestionObj )}
            </div>
            <div className="h-[20px]"></div>
            <p className="text-xl font-bold">Напишите текст вопроса</p>
            <div className="h-[20px]"></div>
            <div>
                <input
                    onChange={(e) => {
                        updateQuestionObj((prevState) => ({ ...prevState, 'text': e.target.value }));
                        if (e.target.value.length === 1) {
                            updateQuestionObj((prevState) => ({ ...prevState, 'ready': true }));
                        }
                        if (e.target.value.length === 0) {
                            updateQuestionObj((prevState) => ({ ...prevState, 'ready': false }));
                        }
                    }}
                    placeholder='Текст вопроса. Например: "Какая цель вашей поездки?"'
                    className="h-[50px] w-[500px] pl-[20px] rounded-md bg-white border-black/[0.1] border-[2px]"
                ></input>
                <label>
                    <input
                        className='ml-20 mr-5'
                        type='checkbox'
                        onChange={(e => updateQuestionObj(prevState => ({ ...prevState, 'isMultiple': e.target.checked })))}
                    />
                    Персональный
                </label>
            </div>
            <div className="h-[20px]"></div>
            <div
                onClick={(e) => {
                    if (newQuestionObj['ready']) {
                        const clone = structuredClone(country);
                        if (newQuestionObj['type'] !== 'dropdown' && newQuestionObj["type"] !== "choice") {
                            clone['questionnaires'][0]['sections'][newQuestionObj['sectionIndex']]['questions'].push({
                                'isMultiple': newQuestionObj.isMultiple,
                                'question': newQuestionObj['text'],
                                'type': newQuestionObj['type'],
                            });
                        } else {
                            clone['questionnaires'][0]['sections'][newQuestionObj['sectionIndex']]['questions'].push({
                                'isMultiple': newQuestionObj.isMultiple,
                                'question': newQuestionObj['text'],
                                'type': newQuestionObj['type'],
                                'options': [],
                            });
                        }
                        updateCountry(clone);
                        changeQuestionState(false);
                        updateQuestionObj(prevState => ({ ...prevState, 'isMultiple': false })) // сброс isMultiple после сохранения вопроса.
                    }
                }}
                className={`w-[100%] h-[50px] rounded-md bg-blue-700 font-bold text-white flex items-center justify-center ${newQuestionObj['ready'] ? "opacity-100" : "opacity-30"}`}
            >
                Добавить новый вопрос
            </div>
        </div>
    )
}

