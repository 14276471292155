import React from 'react';
import closeIcon from "../close.svg";


export function renderOptionQuestionPicker(types, updateOptionQuestion, optionQuestion, country, updateCountry, frontTypes) {
    console.log("renderOptionQuestionPicker")
    // для создания вложенных вопросов
    let questions = [];
    for (let i = 0; i < types.length; i++) {
        // нельзя создавать вложенные дропдауны.
        if (types[i] !== "dropdown") {
            questions.push(
                <div onClick={() => {
                    updateOptionQuestion(prev => ({ ...prev, type: types[i] })); // в шаблоне optionQuestion меняем тип выбранного вопроса, кроме дропдауна.
                }}
                    className={`h-[50px] w-[300px] bg-white rounded-md ${optionQuestion['type'] !== types[i] ? "border-black/[0.1] border-[2px]" : "border-blue-700 border-[5px]"} flex justify-center items-center`}
                >
                    {frontTypes[i]}
                </div>
            )
        }
    }
    return (
        <div className="top-[20%] left-[10%] h-[500px] w-[80%] bg-white rounded-md self-center absolute overflow-x-hidden flex flex-col items-start p-[20px]">
            <div className="flex w-[100%] flex-row justify-between">
                <p className="text-xl font-bold">Выберите тип вопроса для опции</p>
                <img onClick={() => {
                    let clone = structuredClone(optionQuestion);
                    clone['show'] = false;
                    updateOptionQuestion(clone);
                }} alt="closeIcon" src={closeIcon} className='h-[30px]'></img>
            </div>
            <div className="h-[20px]"></div>
            <div className="flex flex-wrap gap-[20px]">
                {questions}
            </div>
            <div className="h-[20px]"></div>
            <p className="text-xl font-bold">Напишите текст вопроса</p>
            <div className="h-[20px]"></div>
            <input onChange={(val) => {
                updateOptionQuestion(prev => ({ ...prev, question: val.target.value }));
            }} placeholder='Текст вопроса. Например: "Какая цель вашей поездки?"' className="h-[50px] w-[500px] pl-[20px] rounded-md bg-white border-black/[0.1] border-[2px]"></input>
            <div className="h-[20px]"></div>
            <div
                onClick={(val) => {
                  console.log("Добавить новый вопрос")
                    console.log(optionQuestion)
                    if (optionQuestion['question'].length > 0) {
                        let countryClone = structuredClone(country);
                        let subQuestionObject = { // структура подвопроса дропдауна
                            'question': optionQuestion['question'],
                            'type': optionQuestion['type'],
                        }
                        console.log(subQuestionObject)
                        if (optionQuestion.type === 'choice') subQuestionObject.options = [] // если подвопрос типа - choice, то в структуру добавляется еще поле options:[] для опций.
                        countryClone.questionnaires[0]
                            .sections[optionQuestion.sectionIndex]
                            .questions[optionQuestion.questionIndex]
                            .options[optionQuestion.optionIndex]
                            .questions.push(subQuestionObject);
                        console.log(structuredClone(countryClone))
                        updateCountry(countryClone);
                        updateOptionQuestion(prev => ({ ...prev, show: false }));
                    }
                }}
                className={`w-[100%] h-[50px] rounded-md bg-blue-700 font-bold text-white flex items-center justify-center ${optionQuestion['question'].length > 0 ? "opacity-100" : "opacity-30"}`}
            >
                Добавить новый вопрос
            </div>
        </div>
    )
}