import React from 'react';

const AddSectionButton = ({changeSectionAdderState}) => {
  return (
    <div onClick={() => { }} className="flex flex-col items-start pl-[60px]">
      <div
        onClick={() => {
          changeSectionAdderState(true);
        }}
        className="h-[50px] w-[400px] flex  bg-black/[0.1] rounded-md justify-center items-center"
      >
        <p className="text-md">Добавить секцию +</p>
      </div>
    </div>
  );
};

export default AddSectionButton;