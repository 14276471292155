import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddGift from '../../../modules/AddGift/components/AddGift';
import GiftList from '../../../modules/GiftList/components/GiftList';

const GiftsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="h-[100%] w-[100%] p-[20px]  flex flex-col items-center fixed bg-gray-200 overflow-scroll">
        <div className="flex flex-row w-[100%] justify-between mb-[20px]">
            <div onClick={() => navigate('/')} className="h-[40px] w-[100px] rounded-md border-black/[0.3] border-[2px] flex items-center justify-center">Назад</div>
            <div className="text-3xl font-bold"> Подарки</div>
            <div className="h-[1px] w-[100px]"></div>
        </div>
        <AddGift />
        <GiftList />
    </div>

  );
};

export default GiftsPage;