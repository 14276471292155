import { Button } from 'antd';
import React from 'react';

const Picture = ({downloadURL, makePictureFirst, index, giftName}) => {
  const makePictureFirstHandler = () => {
    makePictureFirst(index)
  }
  return (
    <div className='flex flex-col mr-[2%]'>
        <div className='shrink-0 relative pb-[150%] mr-[2%] mb-[2%] w-[100%]' style={{backgroundColor:"white"}}>
          <img className='block absolute w-[100%] h-[100%] top-0 left-0 object-contain ' src={downloadURL} alt={giftName}/>
        </div>
        <div>
          <Button size='small' onClick={makePictureFirstHandler}>На главную</Button>
        </div>
      </div>
  );
};

export default Picture;