import React from 'react';

const CountryBar = ({ruCountry, addCountry}) => {

  const onClickHandler = () => {
    addCountry(ruCountry);
  }

  return (
    <div>
      <div
        onClick={onClickHandler}
        className="h-[40px] w-[100%] bg-white border-[2px] rounded-md pl-[20px] pr-[20px] flex flex-row items-center justify-center"
      >
        {ruCountry}
      </div>
      <div className="h-[10px]"></div>
    </div>
  );
};

export default CountryBar;