import React from 'react';
import deleteIcon from "../../../../delete.svg";
import EditableText from '../EditableText';
import { frontTypes, types } from '../../../../variables';
import getRusQuestionType from '../../helpers/getRusQuestionType';

const QuestionBar = ({
  question,
  questionNum,
  questionIndex,
  deleteQuestion,
  changeQuestionName,
}) => {
  const handleQuestionDelete = () => {
    deleteQuestion(questionIndex)
  }
  const questionType = getRusQuestionType(question.type)
  const value = question.question

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row items-center">
      <p className='w-[60px] text-emerald-500	font-bold'>вопрос</p>
        <div className="p-[15px] w-[500px] flex border-[2px] border-black/[0.2]  bg-white rounded-md justify-center items-center gap-[5px]">
          <span>{questionNum}</span>
          <EditableText
            value={value}
            index={questionIndex}
            changeFunc={changeQuestionName}
          />
          <span>{`(${questionType})`}</span>
        </div>
        <div className="w-[20px]"></div>
        <div
          onClick={handleQuestionDelete}
          className="w-[40px] h-[40px] rounded-[30px] bg-gray-300 flex items-center justify-center"
        >
          <img src={deleteIcon} alt="deleteIcon" className="h-[20px]"></img>
        </div>
      </div>
      <div className="h-[10px]"></div>
    </div>
  );
};

export default QuestionBar;
