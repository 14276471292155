var c = [
    'Афганистан',
    'Албания',
    'Антарктика',
    'Алжир',
    'Американское Самоа',
    'Андорра',
    'Ангола',
    'Антигуа и Барбуда',
    'Азербайджан',
    'Аргентина',
    'Австралия',
    'Австрия',
    'Багамские Острова',
    'Бахрейн',
    'Бангладеш',
    'Армения',
    'Барбадос',
    'Бельгия',
    'Бермудские Острова',
    'Бутан',
    'Боливия',
    'Босния и Герцеговина',
    'Ботсвана',
    'Остров Буве',
    'Бразилия',
    'Белиз',
    'Британская территория в Индийском океане',
    'Соломоновы Острова',
    'Британские Виргинские острова',
    'Бруней',
    'Болгария',
    'Мьянма',
    'Бурунди',
    'Белоруссия',
    'Камбоджа',
    'Камерун',
    'Канада',
    'Кабо-Верде',
    'Каймановы острова',
    'Центральноафриканская Республика',
    'Шри-Ланка',
    'Чад',
    'Чили',
    'Китай',
    'Остров Рождества',
    'Кокосовые острова',
    'Колумбия',
    'Коморы',
    'Майотта',
    'Республика Конго',
    'Демократическая Республика Конго',
    'Острова Кука',
    'Коста-Рика',
    'Хорватия',
    'Куба',
    'Кипр',
    'Чехия',
    'Бенин',
    'Дания',
    'Доминика',
    'Доминиканская Республика',
    'Эквадор',
    'Сальвадор',
    'Экваториальная Гвинея',
    'Эфиопия',
    'Эритрея',
    'Эстония',
    'Фарерские острова',
    'Фолклендские острова',
    'Южная Георгия и Южные Сандвичевы острова',
    'Фиджи',
    'Финляндия',
    'Аландские острова',
    'Франция',
    'Французская Гвиана',
    'Французская Полинезия',
    'Французские Южные и Антарктические территории',
    'Джибути',
    'Габон',
    'Грузия',
    'Гамбия',
    'Палестина',
    'Германия',
    'Гана',
    'Гибралтар',
    'Кирибати',
    'Греция',
    'Гренландия',
    'Гренада',
    'Гваделупа',
    'Гуам',
    'Гватемала',
    'Гвинея',
    'Гайана',
    'Республика Гаити',
    'Остров Херд и острова Макдональд',
    'Ватикан',
    'Гондурас',
    'Гонконг',
    'Венгрия',
    'Исландия',
    'Индия',
    'Индонезия',
    'Иран',
    'Ирак',
    'Ирландия',
    'Израиль',
    'Италия',
    'Кот-д’Ивуар',
    'Ямайка',
    'Япония',
    'Казахстан',
    'Иордания',
    'Кения',
    'КНДР',
    'Южная Корея',
    'Кувейт',
    'Киргизия',
    'Лаос',
    'Ливан',
    'Лесото',
    'Латвия',
    'Либерия',
    'Ливия',
    'Лихтенштейн',
    'Литва',
    'Люксембург',
    'Макао',
    'Мадагаскар',
    'Малави',
    'Малайзия',
    'Мальдивы',
    'Мали',
    'Мальта',
    'Мартиника',
    'Мавритания',
    'Маврикий',
    'Мексика',
    'Монако',
    'Монголия',
    'Молдова',
    'Черногория',
    'Монтсеррат',
    'Марокко',
    'Мозамбик',
    'Оман',
    'Намибия',
    'Науру',
    'Непал',
    'Нидерланды',
    'Кюрасао',
    'Аруба',
    'Синт-Мартен',
    'Бонэйр, Синт-Эстатиус и Саба',
    'Новая Каледония',
    'Вануату',
    'Новая Зеландия',
    'Никарагуа',
    'Нигер',
    'Нигерия',
    'Ниуэ',
    'Норфолк',
    'Норвегия',
    'Северные Марианские острова',
    'Внешние малые острова США',
    'Микронезия',
    'Маршалловы Острова',
    'Палау',
    'Пакистан',
    'Панама',
    'Папуа — Новая Гвинея',
    'Парагвай',
    'Перу',
    'Филиппины',
    'Острова Питкэрн',
    'Польша',
    'Португалия',
    'Гвинея-Бисау',
    'Восточный Тимор',
    'Пуэрто-Рико',
    'Катар',
    'Реюньон',
    'Румыния',
    'Россия',
    'Руанда',
    'Сен-Бартелеми',
    'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
    'Сент-Китс и Невис',
    'Ангилья',
    'Сент-Люсия',
    'Сен-Мартен (Франция)',
    'Сен-Пьер и Микелон',
    'Сент-Винсент и Гренадины',
    'Сан-Марино',
    'Сан-Томе и Принсипи',
    'Саудовская Аравия',
    'Сенегал',
    'Сербия',
    'Сейшельские Острова',
    'Сьерра-Леоне',
    'Сингапур',
    'Словакия',
    'Словения',
    'Сомали',
    'Южно-Африканская Республика',
    'Зимбабве',
    'Испания',
    'Южный Судан',
    'Судан',
    'Западная Сахара',
    'Суринам',
    'Шпицберген и Ян-Майен',
    'Свазиленд',
    'Швеция',
    'Швейцария',
    'Сирия',
    'Таджикистан',
    'Таиланд',
    'Того',
    'Токелау',
    'Тонга',
    'Тринидад и Тобаго',
    'Объединённые Арабские Эмираты',
    'Тунис',
    'Турция',
    'Туркменистан',
    'Теркс и Кайкос',
    'Тувалу',
    'Уганда',
    'Украина',
    'Македония',
    'Египет',
    'Великобритания',
    'Гернси',
    'Джерси',
    'Остров Мэн',
    'Танзания',
    'США',
    'Виргинские Острова',
    'Буркина-Фасо',
    'Уругвай',
    'Узбекистан',
    'Венесуэла',
    'Уоллис и Футуна',
    'Самоа',
    'Йемен',
    'Замбия',
    'Вьетнам',
];

const c_eng = [
    'Afghanistan',
    'Albania',
    'Antarctic',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Azerbaijan',
    'Argentina',
    'Australia',
    'Austria',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Armenia',
    'Barbados',
    'Belgium',
    'Bermuda',
    'Butane',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Isle of Bouvet',
    'Brazil',
    'Belize',
    'British Indian Ocean Territory',
    'Solomon islands',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Myanmar',
    'Burundi',
    'Belarus',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman islands',
    'Central African Republic',
    'Sri Lanka',
    'Chad',
    'Chile',
    "People's Republic of China",
    'Christmas Island',
    'Cocos Islands',
    'Colombia',
    'Comory',
    'Mayotte',
    'Republic of the Congo',
    'Democratic Republic of the Congo',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Benin',
    'Denmark',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Salvador',
    'Equatorial Guinea',
    'Ethiopia',
    'Eritrea',
    'Estonia',
    'Faroe islands',
    'Falkland Islands',
    'South Georgia and the South Sandwich Islands',
    'Fiji',
    'Finland',
    'Aland Islands',
    'France',
    'French Guiana',
    'French polynesia',
    'French Southern and Antarctic Territories',
    'Djibouti',
    'Gabon',
    'Georgia',
    'Gambia',
    'Palestine',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Kiribati',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guinea',
    'Guyana',
    'Republic of Haiti',
    'Heard Island and the McDonald Islands',
    'Vatican',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Kazakhstan',
    'Jordan',
    'Kenya',
    'DPRK',
    'The Republic of Korea',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Lebanon',
    'Lesotho',
    'Latvia',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Monaco',
    'Mongolia',
    'Moldova',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Oman',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'Curaçao',
    'Aruba',
    'Sint Maarten',
    'Bonaire, St. Eustatius and Saba',
    'New Caledonia',
    'Vanuatu',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk',
    'Norway',
    'Northern Mariana Islands',
    'U.S. Minor Outlying Islands',
    'Micronesia',
    'Marshall Islands',
    'Palau',
    'Pakistan',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn Islands',
    'Poland',
    'Portugal',
    'Guinea-Bissau',
    'East Timor',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Barthelemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Anguilla',
    'Saint Lucia',
    'Saint-Martin (France)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Somalia',
    'South African Republic',
    'Zimbabwe',
    'Spain',
    'South Sudan',
    'Sudan',
    'West Sahara',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tajikistan',
    'Thailand',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'United Arab Emirates',
    'Tunisia',
    'Türkiye',
    'Turkmenistan',
    'Turks and Caicos',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'Republic of Macedonia',
    'Egypt',
    'Great Britain',
    'Guernsey',
    'Jersey',
    'Isle Of Man',
    'Tanzania',
    'USA',
    'Virgin Islands',
    'Burkina Faso',
    'Uruguay',
    'Uzbekistan',
    'Venezuela',
    'Wallis and Futuna',
    'Samoa',
    'Yemen',
    'Zambia',
    'Vietnam',
];



const country_codes = [
    'AF', 'AL', 'AQ', 'DZ', 'AS', 'AD', 'AO', 'AG', 'AZ', 'AR', 'AU', 'AT', 'BS', 'BH', 'BD', 'AM', 'BB', 'BE',
    'BM', 'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'BZ', 'IO', 'SB', 'VG', 'BN', 'BG', 'MM', 'BI', 'BY', 'KH', 'CM',
    'CA', 'CV', 'KY', 'CF', 'LK', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'YT', 'CG', 'CD', 'CK', 'CR', 'HR',
    'CU', 'CY', 'CZ', 'BJ', 'DK', 'DM', 'DO', 'EC', 'SV', 'GQ', 'ET', 'ER', 'EE', 'FO', 'FK', 'GS', 'FJ', 'FI',
    'AX', 'FR', 'GF', 'PF', 'TF', 'DJ', 'GA', 'GE', 'GM', 'PS', 'DE', 'GH', 'GI', 'KI', 'GR', 'GL', 'GD', 'GP',
    'GU', 'GT', 'GN', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL', 'IT',
    'CI', 'JM', 'JP', 'KZ', 'JO', 'KE', 'KP', 'KR', 'KW', 'KG', 'LA', 'LB', 'LS', 'LV', 'LR', 'LY', 'LI', 'LT',
    'LU', 'MO', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MQ', 'MR', 'MU', 'MX', 'MC', 'MN', 'MD', 'ME', 'MS', 'MA',
    'MZ', 'OM', 'NA', 'NR', 'NP', 'NL', 'CW', 'AW', 'SX', 'BQ', 'NC', 'VU', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF',
    'NO', 'MP', 'UM', 'FM', 'MH', 'PW', 'PK', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'GW', 'TL', 'PR',
    'QA', 'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'AI', 'LC', 'MF', 'PM', 'VC', 'SM', 'ST', 'SA', 'SN', 'RS',
    'SC', 'SL', 'SG', 'SK', 'SI', 'SO', 'ZA', 'ZW', 'ES', 'SS', 'SD', 'EH', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY',
    'TJ', 'TH', 'TG', 'TK', 'TO', 'TT', 'AE', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'MK', 'EG', 'GB', 'GG',
    'JE', 'IM', 'TZ', 'US', 'VI', 'BF', 'UY', 'UZ', 'VE', 'WF', 'WS', 'YE', 'ZM', 'VN'];

const currencies = [
  'AFN', 'ALL', 'null', 'DZD', 'USD', 'EUR', 'AOA', 'XCD', 'AZN', 'ARS', 'AUD', 'EUR', 'BSD', 'BHD', 'BDT', 'AMD', 'BBD', 'EUR', 'BMD', 'BTN', 'BOB', 'BAM', 'BWP', 'NOK', 'BRL', 'BZD', 'USD', 'SBD', 'USD', 'BND', 'BGN', 'MMK', 'BIF', 'BYR', 'KHR', 'XAF', 'CAD', 'CVE', 'KYD', 'XAF', 'LKR', 'XAF', 'CLP', 'CNY', 'AUD', 'AUD', 'COP', 'KMF', 'EUR', 'XAF', 'CDF', 'NZD', 'CRC', 'HRK', 'CUP', 'EUR', 'CZK', 'XOF', 'DKK', 'XCD', 'DOP', 'USD', 'USD', 'XAF', 'ETB', 'ERN', 'EUR', 'DKK', 'FKP', 'GBP', 'FJD', 'EUR', 'EUR', 'EUR', 'EUR', 'XPF', 'EUR', 'DJF', 'XAF', 'GEL', 'GMD', 'ILS', 'EUR', 'GHS', 'GIP', 'AUD', 'EUR', 'DKK', 'XCD', 'EUR', 'USD', 'GTQ', 'GNF', 'GYD', 'HTG', 'AUD', 'EUR', 'HNL', 'HKD', 'HUF', 'ISK', 'INR', 'IDR', 'IRR', 'IQD', 'EUR', 'ILS', 'EUR', 'XOF', 'JMD', 'JPY', 'KZT', 'JOD', 'KES', 'KPW', 'KRW', 'KWD', 'KGS', 'LAK', 'LBP', 'LSL', 'EUR', 'LRD', 'LYD', 'CHF', 'EUR', 'EUR', 'MOP', 'MGA', 'MWK', 'MYR', 'MVR', 'XOF', 'EUR', 'EUR', 'MRO', 'MUR', 'MXN', 'EUR', 'MNT', 'MDL', 'EUR', 'XCD', 'MAD', 'MZN', 'OMR', 'NAD', 'AUD', 'NPR', 'EUR', 'ANG', 'AWG', 'ANG', 'USD', 'XPF', 'VUV', 'NZD', 'NIO', 'XOF', 'NGN', 'NZD', 'AUD', 'NOK', 'USD', 'USD', 'USD', 'USD', 'USD', 'PKR', 'PAB', 'PGK', 'PYG', 'PEN', 'PHP', 'NZD', 'PLN', 'EUR', 'XOF', 'USD', 'USD', 'QAR', 'EUR', 'RON', 'RUB', 'RWF', 'EUR', 'SHP', 'XCD', 'XCD', 'XCD', 'EUR', 'EUR', 'XCD', 'EUR', 'STD', 'SAR', 'XOF', 'RSD', 'SCR', 'SLL', 'SGD', 'EUR', 'EUR', 'SOS', 'ZAR', 'ZWL', 'EUR', 'SSP', 'SDG', 'MAD', 'SRD', 'NOK', 'SZL', 'SEK', 'CHF', 'SYP', 'TJS', 'THB', 'XOF', 'NZD', 'TOP', 'TTD', 'AED', 'TND', 'TRY', 'TMT', 'USD', 'AUD', 'UGX', 'UAH', 'MKD', 'EGP', 'GBP', 'GBP', 'GBP', 'GBP', 'TZS', 'USD', 'USD', 'XOF', 'UYU', 'UZS', 'VEF', 'XPF', 'WST', 'YER', 'ZMW', 'VND',
]
var transl = new Array();
transl['А'] = 'A'; transl['а'] = 'a';
transl['Б'] = 'B'; transl['б'] = 'b';
transl['В'] = 'V'; transl['в'] = 'v';
transl['Г'] = 'G'; transl['г'] = 'g';
transl['Д'] = 'D'; transl['д'] = 'd';
transl['Е'] = 'E'; transl['е'] = 'e';
transl['Ё'] = 'Yo'; transl['ё'] = 'yo';
transl['Ж'] = 'Zh'; transl['ж'] = 'zh';
transl['З'] = 'Z'; transl['з'] = 'z';
transl['И'] = 'I'; transl['и'] = 'i';
transl['Й'] = 'J'; transl['й'] = 'j';
transl['К'] = 'K'; transl['к'] = 'k';
transl['Л'] = 'L'; transl['л'] = 'l';
transl['М'] = 'M'; transl['м'] = 'm';
transl['Н'] = 'N'; transl['н'] = 'n';
transl['О'] = 'O'; transl['о'] = 'o';
transl['П'] = 'P'; transl['п'] = 'p';
transl['Р'] = 'R'; transl['р'] = 'r';
transl['С'] = 'S'; transl['с'] = 's';
transl['Т'] = 'T'; transl['т'] = 't';
transl['У'] = 'U'; transl['у'] = 'u';
transl['Ф'] = 'F'; transl['ф'] = 'f';
transl['Х'] = 'X'; transl['х'] = 'x';
transl['Ц'] = 'C'; transl['ц'] = 'c';
transl['Ч'] = 'Ch'; transl['ч'] = 'ch';
transl['Ш'] = 'Sh'; transl['ш'] = 'sh';
transl['Щ'] = 'Shh'; transl['щ'] = 'shh';
transl['Ъ'] = '"'; transl['ъ'] = '"';
transl['Ы'] = 'Y\''; transl['ы'] = 'y\'';
transl['Ь'] = '\''; transl['ь'] = '\'';
transl['Э'] = 'E\''; transl['э'] = 'e\'';
transl['Ю'] = 'Yu'; transl['ю'] = 'yu';
transl['Я'] = 'Ya'; transl['я'] = 'ya';

let flagUrl;
let previewUrl;
let gotCountry = false;
let gotMiniCountry = false;
let miniCountryDoc;

function updateGotCountry(val) {
    gotCountry = val;
}

function updatePreviewUrl(val) {
    previewUrl = val;
}

function updateFlagUrl(val) {
    flagUrl = val;
}

function updateGotMiniCountry(val) {
    gotMiniCountry = val;
}

function updateMiniCountryDoc(val) {
    miniCountryDoc = val;
}


function returnTemplate(name, ind) {
    return {
        "active": false,
        "chosenGifts": [],
        'name': name,
        'images': {
            'flag': "",
            'preview': "",
        },
        "services": [],
        "contact_info": {
            "address": "",
            "time": "",
            "phone": "",
            "email": "",
        },
        "additional_services": [],
        "available_appointments": {
            'tourist': [],
        },
        "faq":[],
        "fees_USD": {
            "tourist": 0,
        },
        "review_duration": 5,
        "visa_process_duration": 15,
        "visaInfo": {
          'generalInfo': '',
          'requiredDocs': '',
          'submissionSteps': '',
        },
        "usd_conversion": "",
        "currency_code": currencies[ind],
        "questionnaires": [
            {
                "sections": [],
                "type": "tourist",
            },
        ],
        "relations": {
            "visa": [],
            "previsa": [],
            "novisa": [],
            "forbidden": [],
        },
        "country_id": country_codes[ind].toLowerCase(),
    };
}

function translFunc(inputText) {
    let text = inputText.split(" ").join("_");
    let result = '';
    for (let i = 0; i < text.length; i++) {
        if (transl[text[i]] != undefined) { result += transl[text[i]]; }
        else { result += text[i]; }
    }
    return result;
}

export {
    c, c_eng, currencies, transl, country_codes, flagUrl, previewUrl, gotCountry,
    updateGotCountry, updatePreviewUrl, updateFlagUrl,
    returnTemplate, translFunc, gotMiniCountry, updateGotMiniCountry, miniCountryDoc, updateMiniCountryDoc
};

export const frontTypes = ['Текст', 'Кнопки', 'Фото', 'Список', 'Дата', 'Дропдаун'];
export const types = ['text', 'choice', 'photo', 'list', 'date', 'dropdown'];
export const visaTypes = ['visa', 'previsa', 'novisa', 'forbidden'];

