import React from 'react';
import SectionBar from './SectionBar';
import Questions from '../questions/Questions';
import AddQuestionButton from '../questions/AddQuestionButton';

const Section = ({
  section,
  sectionIndex,
  expanded,
  expandSection,
  deleteSection,
  changeSectionName,
  country,
  updateCountry,
  optionQuestion,
  updateDropOption,
  updateOptionQuestion,
  updateQuestionObj,
  changeQuestionState
}) => {
  return (
    <div className="flex flex-col">
        <SectionBar
          section={section}
          sectionIndex={sectionIndex}
          expanded={expanded}
          expandSection={expandSection}
          deleteSection={deleteSection}
          changeSectionName={changeSectionName}
        />
        <div className='h-[10px]'></div>
        {expanded[sectionIndex] ? (
          <div className="flex flex-col pl-[50px] items-start">
            <Questions
              questions={section.questions}
              country={country}
              optionQuestion={optionQuestion}
              updateDropOption={updateDropOption}
              updateOptionQuestion={updateOptionQuestion}
              updateCountry={updateCountry}
              sectionIndex={sectionIndex}
            />
            <AddQuestionButton
              sectionIndex={sectionIndex}
              updateQuestionObj={updateQuestionObj}
              changeQuestionState={changeQuestionState}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
  );
};

export default Section;