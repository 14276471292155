import React from 'react';

const AddQuestionButton = ({sectionIndex, updateQuestionObj, changeQuestionState}) => {
  return (
    <>
      <div
        onClick={(e) => {
          // let clone = structuredClone(newQuestionObj);
          // clone['sectionIndex'] = i;
          // updateQuestionObj(clone);
          updateQuestionObj(prev => ({ ...prev, sectionIndex}))
          changeQuestionState(true);
        }}
        className="h-[50px] w-[500px] flex  bg-black/[0.1] rounded-md justify-center items-center ml-[60px]"
      >
        <p className="text-md">Добавить вопрос +</p>
      </div>
      <div className="h-[30px]"></div>
    </>
  );
};

export default AddQuestionButton;