import React from 'react';
import NestedChoiceOptionBar from './NestedChoiceOptionBar';

const NestedChoiceOptions = ({
  subQuestion,
  country,
  updateCountry,
  sectionIndex,
  questionIndex,
  optionIndex,
  subQuestionIndex,
}) => {
  const deleteOption = (subOptionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions[questionIndex].options[optionIndex].questions[subQuestionIndex].options.splice(subOptionIndex, 1);
    updateCountry(clone);
  }

  const changeNestedChoiceOption = (event, subOptionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions[questionIndex].options[optionIndex].questions[subQuestionIndex].options[subOptionIndex].option = event.target.value;
    updateCountry(clone);
  }

  return subQuestion?.options.map((subOption, subOptionIndex) => {
    const subOptionNum = `Опция ${subOptionIndex + 1}:`;

    return (
      <NestedChoiceOptionBar
        subOption={subOption}
        subOptionNum={subOptionNum}
        subOptionIndex={subOptionIndex}
        deleteOption={deleteOption}
        changeNestedChoiceOption={changeNestedChoiceOption}
      />
    )
  });
}

export default NestedChoiceOptions;
