import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase.config";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { browserLocalPersistence, initializeAuth } from "firebase/auth";

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage();
export const auth = initializeAuth(app, {
  // TODO: запоминать авторизованного пользователя, или нет
  persistence: browserLocalPersistence,
});

