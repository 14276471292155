import React from 'react';
import { frontTypes, types } from '../../../../variables';
import deleteIcon from "../../../../delete.svg";
import EditableText from '../EditableText';
import getRusQuestionType from '../../helpers/getRusQuestionType';

const SubQuestionBar = ({
  subQuestion,
  subQuestionIndex,
  subQuestionNumber,
  deleteSubQuestion,
  changeSubQuestion
}) => {

  const handleDeleteSubQuestion = () => {
    deleteSubQuestion(subQuestionIndex)
  }

  const subQuestionType = getRusQuestionType(subQuestion.type)
  const value = subQuestion.question;
  return (
    <div>
      <div className="flex flex-row items-center">
        <p className='w-[60px] text-violet-700 font-bold'>вопрос 2ур.</p>
        <div
          className="p-[15px] w-[500px] bg-white border-[2px] border-black/[0.1] rounded-md flex items-center justify-center gap-[5px]"
        >
          <span>{subQuestionNumber}</span>
          <EditableText
            value={value}
            index={subQuestionIndex}
            changeFunc={changeSubQuestion}
          />
          <span>{`(${subQuestionType})`}</span>
        </div>
        <div className="w-[20px]"></div>
        <div
          onClick={handleDeleteSubQuestion}
          className="w-[40px] h-[40px] rounded-[40px] bg-gray-300 flex items-center justify-center"
        >
          <img className="h-[20px]" src={deleteIcon}></img>
        </div>
      </div>
      <div className="h-[10px]"></div>
    </div>
  );
};

export default SubQuestionBar;