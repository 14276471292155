import React from 'react';
import closeIcon from "../close.svg"

export function renderOptionPicker(updateDropOption, dropOption, country, updateCountry) {
    return (
      <div className="top-[40%] left-[10%] h-[200px] w-[80%] bg-white rounded-md self-center absolute overflow-x-hidden flex flex-col items-start p-[20px]">
        <div className="flex w-[100%] flex-row justify-between">
          <p className="text-xl font-bold">Имя новой опции</p>
          <img
            onClick={() => {
              updateDropOption(prev => ({ ...prev, show: false, nestedChoiceQuestion: false })); // закрываем модалку добавления новой опции
            }}
            alt="closeIcon"
            className="h-[30px]"
            src={closeIcon}
          ></img>
        </div>
        <div className="h-[10px]"></div>
        <input
          onChange={(val) => {
            updateDropOption(prev => ({ ...prev, option: val.target.value }));
          }}
          placeholder='Добавьте название новой опции'
          className="h-[50px] w-[100%] pl-[20px] rounded-md border-black/[0.1] border-[2px]"
        ></input>
        <div className="h-[20px]"></div>
        <div
          onClick={() => { // добавляем опцию в массив опций в шаблон страны
            if (dropOption['option'].length > 0) { // если название опции не пустое.
              let cloneCountry = structuredClone(country);
              const newOption = {
                'option': dropOption['option'], // текст опции
                'questions': [], // список подвопросов, для чойсов пополняться не будет.

              }
              if (dropOption.nestedChoiceQuestion) {// если этот флаг true, значит мы добавляем опции чойсам вложенным в дропдаун.
                cloneCountry.questionnaires[0].sections[dropOption.sectionIndex].questions[dropOption.index].options[dropOption.dropdownOptionIndex].questions[dropOption.dropdownSubquestionIndex].options.push(newOption)
              } else {
                cloneCountry.questionnaires[0].sections[dropOption.sectionIndex].questions[dropOption.index].options.push(newOption)
              }
              updateCountry(cloneCountry);
              updateDropOption(prev => ({ ...prev, show: false, nestedChoiceQuestion: false }));
            }
          }}
          className="h-[50px] w-[100%] rounded-md bg-blue-600 flex items-center justify-center text-white"
        >
          Добавить
        </div>
      </div>
    )
  }
