import React from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase.services';
import Auth from './Auth'

const contentInsideLayoutStyle = {
  width:"45%",
  padding:'50px 100px',
  minHeight:"49vh",
  justifyContent:"center",
  boxShadow:"3px 3px 6px 2px #0000002c",
  backgroundColor: 'white',
}

const AuthContainer = () => {
  const onFinish = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      console.log(e)
      throw e;
    }
  }

  return (
    <div
      style={contentInsideLayoutStyle}
    >
      <Auth onFinish={onFinish}/>
    </div>
  );
};

export default AuthContainer;