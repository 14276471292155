import React from 'react';
import AddSectionButton from './sections/AddSectionButton';
import Sections from './sections/Sections';
import setExpandedConfig from '../helpers/setExpandedConfig';

const Questionnaire = ({
  country,
  expanded,
  changeExpanded,
  updateCountry,
  updateDropOption,
  optionQuestion,
  updateQuestionObj,
  updateOptionQuestion,
  changeQuestionState,
  changeSectionAdderState
}) => {
  const sections = country.questionnaires[0].sections// секции анкеты для туристической визы.

  // if (expanded.length !== sections.length && sections.length !== 0) {
  //   changeExpanded(prev => setExpandedConfig(prev, sections.length));
  // }

  return (
    <div className="flex flex-col items-center">
      <div>
        <Sections
          sections={sections}
          country={country}
          updateCountry={updateCountry}
          expanded={expanded}
          changeExpanded={changeExpanded}
          updateQuestionObj={updateQuestionObj}
          optionQuestion={optionQuestion}
          changeQuestionState={changeQuestionState}
          updateDropOption={updateDropOption}
          updateOptionQuestion={updateOptionQuestion}
        />
        <AddSectionButton
          changeSectionAdderState={changeSectionAdderState}
        />
      </div>
    </div>
  )
};

export default Questionnaire;
