import { useAuthState } from "react-firebase-hooks/auth";
import { getAdminsRef } from "../Auth/reference";
import { auth } from "../firebase/firebase.services";
import { useDocumentData } from "react-firebase-hooks/firestore";
import loadingIcon from "../loading.gif";
import { Navigate, Route, Routes } from "react-router-dom";
import { getAuthorizedOperator } from "../Auth/utils";
import AccessDenied from "../components/AccessDenied";
import App from "../App";
import Blogs from "../Blogs/Blogs";
import EntryPage from "../Auth/EntryPage";
import AuthContainer from "../Auth/AuthContainer";
import GiftsPage from "../pages/Gifts/components/GiftsPage";

const ADMINS_REF = getAdminsRef();
const ACCESS_KEY = 'country-editor';

export default function Switcher() {
  const [user, userLoading, userError] = useAuthState(auth);
  const [adminsData, adminsLoading, adminsError, adminsDocSnapshot] = useDocumentData(ADMINS_REF)

  if (userLoading || adminsLoading) {
    return (
      <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img src={loadingIcon} alt='loading'></img>
      </div>
    )
  }

  if (userError || adminsError) {
    console.log(userError || adminsError);
    return;
  }
  if (!user) {
    return (
      <Routes>
        <Route path='/*' element={<Navigate to='auth' replace={true} />}/>
        <Route
          path='/auth'
          element={(
            <EntryPage>
              <AuthContainer />
            </EntryPage>
          )}
        />
      </Routes>
    )
  }

  const authorizedUser = getAuthorizedOperator(adminsData.admins, user.uid);
  const accessAllowed = authorizedUser.accesses ? authorizedUser.accesses.includes(ACCESS_KEY) : false;

  return (
    //TODO: вынести в функцию, возвращающую допустимые роуты после всех проверок: auth и AccessAllowed.
    <Routes>
      {!accessAllowed ? (
        <>
          <Route path='/*' element={<Navigate to='no-access' replace={true} />}/>
          <Route path='/no-access' element={<AccessDenied />}/>
        </>
      ) : (
        <>
          <Route path='/editor' element={<App />}/>
          <Route path='/blogs' element={<Blogs />}/>
          <Route path='/gifts' element={<GiftsPage />}/>
          <Route path='/*' element={<Navigate to='editor' replace={true} />}/>
        </>
      )}
    </Routes>
  )
}
