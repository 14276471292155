import React, { useState } from 'react';
import QuestionList from './QuestionList';
import NewQuestionButton from './NewQuestionButton';

const Faq = ({country, updateCountry}) => {

  return (
    <div className="flex flex-col w-[100%] border-black/[0.3] border-[2px] rounded-md p-[20px]">
      <div className="text-xl font-bold text-center">6. Часто задаваемые вопросы</div>
      <div className="h-[20px]"></div>
      <QuestionList country={country} updateCountry={updateCountry} />
      <NewQuestionButton country={country} updateCountry={updateCountry} />
    </div>
  );
};

export default Faq;