import React from 'react';
import Options from '../options/Options';
import deleteIcon from "../../../../delete.svg";
import AddOptionButton from '../options/AddOptionButton';
import QuestionBar from './QuestionBar';
import { frontTypes, types } from '../../../../variables';

const Question = ({
  question,
  questionNum,
  country,
  updateCountry,
  sectionIndex,
  questionIndex,
  optionQuestion,
  updateDropOption,
  updateOptionQuestion,
  changeQuestionName,
  deleteQuestion,
}) => {

  const addOption = () => {
    updateDropOption(prev => {
      return {
        ...prev,
        show: true,
        sectionIndex,
        index: questionIndex,
      }
    })
  }

  return (
    <div>
      <QuestionBar
        question={question}
        questionNum={questionNum}
        questionIndex={questionIndex}
        deleteQuestion={deleteQuestion}
        changeQuestionName={changeQuestionName}
      />
      {(question.type === "dropdown" || question.type === "choice") ? (
        <div className="pl-[50px] flex flex-col items-start">
          <Options
            question={question}
            country={country}
            updateCountry={updateCountry}
            optionQuestion={optionQuestion}
            updateDropOption={updateDropOption}
            updateOptionQuestion={updateOptionQuestion}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
          <AddOptionButton
            addOption={addOption}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Question;
