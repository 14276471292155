import React from 'react';
import GiftPreview from './GiftPreview';

const AllGiftsCards = ({giftsData, selectGift, chosenGiftsState}) => {

  const gifts = giftsData.map(gift => {
    return <GiftPreview key={gift.id} gift={gift} selectGift={selectGift} isChosen={chosenGiftsState.includes(gift.id)}/>
  })

  return (
    <div style={{display:'flex', padding:'5px', marginRight:'15px', flexWrap:'wrap', alignContent:'flex-start', height:'70vh', gap:'2%', overflow: 'auto'}}>
      {gifts}
    </div>
  );
};

export default AllGiftsCards;