import { c_eng, c, updatePreviewUrl, updateFlagUrl, updateGotCountry, returnTemplate} from "../variables";
import { getCountry } from "./getCountry";


export function differentCountry(name, cDocData, updateCountry, changeDocLoadState, updateMakingChanges) { // устанавливает шаблон новой страны
  let ind = c.findIndex((e) => e === name);
  updatePreviewUrl("");
  updateFlagUrl("");
  let cl = returnTemplate(name, ind);
  updateCountry(cl); // устанавливает пустой шаблон новой страны
  changeDocLoadState(false);
  updateGotCountry(false);
  console.log('changing country');
  getCountry(name, c, c_eng, cDocData, (val) => { updateCountry(val) },
    (val) => { changeDocLoadState(val) }, (val) => { updateMakingChanges(val) }, (val) => { changeDocLoadState(val) }); // непонятно зачем отсюда вызывать эту функцию.
}
