import React from 'react';
import Question from './Question';
import { frontTypes, types } from '../../../../variables';

const Questions = ({
  questions,
  country,
  optionQuestion,
  updateDropOption,
  updateOptionQuestion,
  updateCountry,
  sectionIndex,
}) => {
  const changeQuestionName = (event, questionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions[questionIndex].question = event.target.value;
    updateCountry(clone);
  }

  const deleteQuestion = (questionIndex) => {
    let clone = structuredClone(country);
    clone.questionnaires[0].sections[sectionIndex].questions.splice(questionIndex, 1);
    updateCountry(clone);
  }

  return questions.map((question, questionIndex) => {
    const questionNum = `${sectionIndex + 1}.${questionIndex + 1}. `;

    return (
      <Question
        question={question}
        questionNum={questionNum}
        country={country}
        updateCountry={updateCountry}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
        optionQuestion={optionQuestion}
        updateDropOption={updateDropOption}
        updateOptionQuestion={updateOptionQuestion}
        changeQuestionName={changeQuestionName}
        deleteQuestion={deleteQuestion}
      />
    )
  })
};

export default Questions;