import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import createFileRef from '../../../firebase/createFileRef';
import { uploadBytesResumable } from 'firebase/storage';
import UploadPictures from './UploadPictures';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase.services';
import getCountryNameEng from '../../../utils/getCountryNameEng';
import { nanoid } from 'nanoid';
import getUploadedFileDownloadURL from '../../../firebase/getUploadedFileDownloadURL';

// const createInitialGiftObject = () => {
//   return {
//     name:'',
//     description: '',
//     fullDescription: '',
//   }
// }

const AddGift = ({countryCode}) => {
  const [ newGiftObj, setNewGiftObj ] = useState({name:'', description:'', fullDescription:''});
  const [ filesUploading, setFilesUploading ] = useState(false);
  const [ pictureList, setPictureList ] = useState([]);
  console.log(newGiftObj)
  console.log(pictureList)
  // console.log(newGiftObj)

  const valueChangeHandler = (e) => {
    setNewGiftObj(prev =>  ({...prev, [e.target.name]:e.target.value}))
  }

  const saveGiftObject = (fullGiftObject) => {
    return new Promise((resolve, reject) => {
      console.log(fullGiftObject)
      const docRefPromise = setDoc(doc(db, `gifts/${fullGiftObject.id}`), fullGiftObject);
      docRefPromise.then(res => resolve(res)).catch((e) => reject(e))
    })
  }

  const saveGift = async () => {
    console.log(1)
    const giftId = nanoid()
    // const links = []
    const promises = [];
    setFilesUploading(true);
    pictureList.forEach(fileInfo => {
      console.log(fileInfo)
      const fileRef = createFileRef(`gifts/${giftId}/${fileInfo.fileOrigin.uid}`);
      const uploadTask = uploadBytesResumable(fileRef, fileInfo.fileOrigin);
      promises.push(uploadTask)
      uploadTask.on('state_changed',
        (taskSnap) => {
          const progress = (taskSnap.bytesTransferred / taskSnap.totalBytes) * 100;
          setPictureList(prev => prev.map(listItem => {
            // console.log(listItem)
            if(listItem.uid === fileInfo.fileOrigin.uid) {
              return {...listItem, status:'uploading', percent:progress}
            }
            return listItem;
          }))
        }, (error) => {

        }, async () => {
          setPictureList(prev => prev.filter(listItem => listItem.uid !== fileInfo.fileOrigin.uid))
          // const downloadURL = await getUploadedFileDownloadURL(uploadTask.snapshot.metadata.fullPath)
          // console.log('еще раз downloadURL: ', downloadURL )
          // links.push(downloadURL)
          // console.log('запушили линку')
        }
      )
    })

    const allTasks = await Promise.all(promises)
    const links = []
    for(let task of allTasks) {
      console.log(task)
      const downloadURL = await getUploadedFileDownloadURL(task.metadata.fullPath);
      links.push(downloadURL);
    }
    const fullGiftObject = {...newGiftObj, id: giftId, pictureLinks: links};
    await saveGiftObject(fullGiftObject);
    setNewGiftObj({name:'', description:'', fullDescription:''});
    setFilesUploading(false)
    // .then(res => {
    //   console.log(2)
    //   res.forEach(async () => {

    //   })
    //   // res.forEach(uploadResult => {
    //   //   links.push(`gs://vkleykakz.appspot.com/${uploadResult.metadata.fullPath}`) // NOTE: прокатит без расширения?
    //   // })
    //   // setNewGiftObj(prev => {
    //   //   console.log('записываем ссылки')
    //   //   return {...prev, pictureLinks: links}
    //   // })
    //   // отправить объект в firestore
    //   const fullGiftObject = {...newGiftObj, id: giftId, pictureLinks: links};
    //   saveGiftObject(fullGiftObject).then(res => {
    //     console.log(4)
    //     setNewGiftObj({name:'', description:'', fullDescription:''});
    //   }).catch(e => console.log(e)).finally(() => {
    //     console.log(5)
    //     setFilesUploading(false)
    //   });
    // })
  }
  const checkSaveButtonDisabled = (newGiftObj, pictureList) => {
    const allFieldsFilled = Object.values(newGiftObj).every(value => !!value);
    return !allFieldsFilled || !pictureList.length
  }
  const saveButtonDisabled = checkSaveButtonDisabled(newGiftObj, pictureList);

  return (
    <div className="p-[20px] w-[50%] border-black/[0.3] border-[2px] rounded-md mb-[2%]">
      <div className='flex flex-col'>
        <div className='flex items-center mb-[5px]'>
          <p>Имя: </p>
          <input
            name='name'
            value={newGiftObj.name}
            className='h-[40px] w-[100%] p-[5px] ml-[10px] bg-white rounded-md '
            onChange={valueChangeHandler}
          />
        </div>
        <div className='flex items-center mb-[5px]'>
          <p>Описание: </p>
          <input
            className='h-[40px] w-[100%] p-[5px] ml-[10px] bg-white rounded-md '
            name='description'
            value={newGiftObj.description}
            onChange={valueChangeHandler}
          />
        </div>
        <div className='flex items-center mb-[5px]'>
          <p className='shrink-0'>Полное описание: </p>
          <textarea
            className='h-[40px] w-[100%] p-[5px] ml-[10px] bg-white rounded-md '
            name='fullDescription'
            value={newGiftObj.fullDescription}
            onChange={valueChangeHandler}
          />
        </div>
        <UploadPictures countryCode={countryCode} setNewGiftObj={setNewGiftObj} pictureList={pictureList} setPictureList={setPictureList} setFilesUploading={setFilesUploading}/>
        <Button
          loading={filesUploading}
          style={{backgroundColor:'#1677ff'}}
          type='primary'
          onClick={saveGift}
          disabled={saveButtonDisabled}
          className={`${saveButtonDisabled ? "opacity-30" : "opacity-100"}`}
        >
          Загрузить
        </Button>
      </div>
    </div>
  );
};

export default AddGift;