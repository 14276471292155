import React from 'react';
import RenderedCountries from './RenderedCountries';
import closeIcon from "../../../close.svg";
import { visaTypes } from '../../../variables';


const CountryPicker = ({ruCountries, fromTopAdd, country, countries, visaType, updateCountries,
  updateCountry, changeCountryPickerState, differentCountry}) => {

  const addCountry = (ruCountry) => {
    if (!fromTopAdd) {
      let clone = structuredClone(country);
      clone.relations[visaTypes[visaType]].push(ruCountry);
      updateCountry(clone);
      changeCountryPickerState(false);
    } else {
      const newCountryOption = <option value={ruCountry}>{ruCountry}</option>;
      updateCountries(prev => [...prev, newCountryOption]); // добавляем в список options выбранную страну.
      changeCountryPickerState(false); // закрываем пикер новых стран.
      differentCountry(ruCountry);
    }
  }

  const countryPickerClose = () => {
    changeCountryPickerState(false);
  }

  return (
    <div className="top-[20%] left-[10%] h-[500px] w-[80%] bg-white rounded-md self-center absolute flex flex-col items-start p-[20px]">
        <div className="overflow-y-scroll w-[100%]">
            <RenderedCountries
              ruCountries={ruCountries}
              fromTopAdd={fromTopAdd}
              countries={countries}
              country={country}
              addCountry={addCountry}
            />
        </div>
        <div
          onClick={countryPickerClose}
          className="top-[10px] right-[10px] absolute"
        >
          <img alt="closeIcon" className="h-[30px]" src={closeIcon}></img>
        </div>
    </div>
  );
};

export default CountryPicker;